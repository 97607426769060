
<template>
    <div>

        <!-- <NavBar /> -->


        <div class="container">


            <div class="row">

                <div class="col">
                    <h2 id="headerText"><b>Challenges Report</b></h2>
                </div>

            </div>


            <div class="row">

                <div v-if="challengeStore.userChallenges.length == 0">

                    <p>You haven't joined any challenge as yet</p>

                    <router-link class="linkStyle" :to="{name: 'ViewAllChallenges'}">

                        <button class="startBtn">START A CHALLENGE</button>
                        
                        
                    </router-link>


                </div>

                <div v-for="(userChallenge, index) in challengeStore.userChallenges" :key="index" class="col wrapper">

                    <router-link class="linkStyle"
                        :to="{ name: 'ViewAChallengeHistory', params: { uuid: userChallenge.challenge.uuid } }">

                        <img class="img-fluid thumIMG" :src="userChallenge.challenge.featured_image_url" />

                        <div class="mainHeader"> {{ userChallenge.challenge.name }}</div>

                        <p class="subHeader">Complete
                            {{ challengeStore.calcProgressPercentage(userChallenge.days_completed,
                                userChallenge.challenge.amt_of_days) }}%
                        </p>

                    </router-link>

                    <div class="row">


                        <div class="col">
                            <img @click="setCurrentHistoryRecord(userChallenge)" class="deleteIcon"
                                :src="require(`@/assets/trash.png`)" />
                        </div>


                    </div>

                </div>





            <!-- <div v-if="challengeStore.userChallenges.length > 0">

                    <div v-for="(userChallenge, index) in challengeStore.userChallenges" :key="index" class="col wrapper">

                        <router-link class="linkStyle"
                            :to="{ name: 'ViewAChallengeHistory', params: { uuid: userChallenge.challenge.uuid } }">

                            <img class="img-fluid thumIMG" :src="userChallenge.challenge.featured_image_url" />

                            <div class="mainHeader"> {{ userChallenge.challenge.name }}</div>

                            <p class="subHeader">Complete
                                {{ challengeStore.calcProgressPercentage(userChallenge.days_completed,
                                    userChallenge.challenge.amt_of_days) }}%
                            </p>

                        </router-link>

                        <div class="row">


                            <div class="col">
                                <img @click="setCurrentHistoryRecord(userChallenge)" class="deleteIcon"
                                    :src="require(`@/assets/trash.png`)" />
                            </div>


                        </div>

                    </div>

                </div>

                <div v-else>

                    <p>You haven't joined any challenge as yet</p>
                    
                    <button class="startBtn">START A CHALLENGE</button>

                        </div> -->




            </div>




            <!-- Modal -->
            <div class="modal fade" id="deleteHistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to delete this challenge
                                history?</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">

                            <div v-if="currentHistory.challenge">

                                <p style="float: left;">{{ currentHistory.challenge.name }}</p>

                            </div>


                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button @click="deleteThisRecord" type="button" class="btn btn-danger">Yes, Delete This
                                History</button>
                        </div>


                    </div>
                </div>
            </div>




        </div>


        <Footer />

    </div>
</template>
  
<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { useChallengeStore } from '@/stores/ChallengeStore';
import { useAuthStore } from '@/stores/AuthStore';
import { Modal } from 'bootstrap'
import { ref } from 'vue';


export default {



    name: 'ChallengeReportList',

    components: {
        // NavBar,
        Footer,

    },

    setup() {

        const challengeStore = useChallengeStore()
        const authStore = useAuthStore()

        //fetch challenges user joined
        challengeStore.fetchAllChallengesUserStarted()

        //check url to toggle arrow
        authStore.checkRoute()

        var currentHistory = ref({})

        var showDeleteChallengehistoryRecordModal = () => {

            var modal = new Modal(document.getElementById('deleteHistoryModal'), {
                keyboard: false
            })

            modal.show()
        }


        var setCurrentHistoryRecord = (userChallenge) => {

            currentHistory.value = userChallenge

            showDeleteChallengehistoryRecordModal()

        }

        var deleteThisRecord = () => {

            challengeStore.deleteChallengeHistoryRecord(currentHistory.value.challenge.uuid)

            hideModal()


            challengeStore.fetchAllChallengesUserStarted()

        }

        var hideModal = () => {

            var currentmodal = document.getElementById('deleteHistoryModal')
            var modal = Modal.getOrCreateInstance(currentmodal)

            modal.hide()

        }


        return {
            challengeStore, setCurrentHistoryRecord, currentHistory, deleteThisRecord
        }


    }




}



</script>
  
  
<style scoped>
.container {
    margin-top: 110px;
    max-width: 1200px !important;
    margin-bottom: 300px;

}


.wrapper {
    max-width: 120px;
    margin: 10px;
    padding: 10px;
    background: linear-gradient(180deg, #ECF9FF 0%, #FFE7CC 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#headerText {
    float: left;
    font-size: 24px;
    margin-bottom: 25px;
}


.linkStyle {
    text-decoration: none;
    color: inherit;
}

.innerWrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}

.thumIMG {
    border-radius: 10px;
    /* max-width: 100px; */
    float: left;
}


.subHeader {
    font-size: 12px;
    float: left;
    text-align: left;

}

.mainHeader {
    font-size: 13px;
    float: left;
    text-align: left;
    font-weight: bold;
    padding-bottom: 0px;
    padding-top: 10px;
}


.deleteIcon {
    width: 15px;
    float: right;
}

.modal-title {
    font-size: 15px !important;
}

.startBtn {
    color: white;
    font-weight: bold;
    background-color: #ea55a0;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
</style>