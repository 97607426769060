import ChallengeService from '@/services/ChallengeService'
import { defineStore } from 'pinia'
// import apiClient from '@/services/MyClient'



export const useChallengeStore = defineStore('challengeStore', {

    state: () => ({

        challenges: [],
        categories: [],
        selectedChallenge: {},
        selectedChallengeWeeks: [],
        currentWeekIndex: 0, 

        isUserEnrolled: 0, //check if user erolled in chall or not
        isLoading: false,

        week: {},
        day: {},
        workout: {},
        rounds: [],
        round_amt: '',

        userChallenges: [],
        weeks: [],
        userChallengeHistory: [],

        challengesList: [],
        challengeDetails: {}

      

    }),



    actions: {

        async fetchHomePageData(){

            ChallengeService.getDataForHomePage()
                .then((response) => {

                    this.challenges = response.data.challenges
                    this.categories = response.data.categories

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },


        async fetchAllChallenges(){ 

            ChallengeService.getAllChallengesList()
                .then((response) => {

                    this.challengesList = response.data.challenges
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },


        async fetchChallengeDetails(slug){


            ChallengeService.getAChallenge(slug)
                .then((response) => {

                    this.selectedChallenge = response.data.challenge
                    this.selectedChallengeWeeks = response.data.weeks

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });


        },

        async fetchDayWorkoutDetails(dayId){

            ChallengeService.getADayWorkoutDetails(dayId)
                .then((response) => {

                    this.selectedChallenge = response.data.challenge
                    this.week = response.data.week
                    this.day = response.data.day
                    this.workout = response.data.workout
                    this.rounds = response.data.rounds
                    this.round_amt = response.data.round_amt
                    this.isUserEnrolled = response.data.isUserEnrolled

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        nextWeek(){

            if(this.currentWeekIndex == (this.selectedChallengeWeeks.length - 1)){
                alert("end of weeks")
            }else{
                this.currentWeekIndex++
            }
 
        },

        prevWeek(){

            if (this.currentWeekIndex > 0){
                this.currentWeekIndex--
            }

            
        },

        enrollUsertToChallenge(dayId){

            this.isLoading = true

            ChallengeService.enrollUserToThisChallenge(this.selectedChallenge.uuid)
                .then((response) => {

                    this.fetchDayWorkoutDetails(dayId)

                    console.log(response.data)

                    this.isLoading = false
                    
                }).catch((err) => {

                    console.log(err)

                    this.isLoading = false
                    
                });

        },

        async fetchAllChallengesUserStarted(){ 

            ChallengeService.getAllChallengesUserStarted()
                .then((response) => {

                    this.userChallenges = response.data.userChallenges

                    // console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        calcProgressPercentage(days_completed, amt_of_days){

            //convert amt_of_days to int
            var amtOfDays = parseInt(amt_of_days)

            var result = Math.round((days_completed / amtOfDays) * 100)

            return result

        },

        getTotalMinsWorkout(your_workout_time) {

            let minutes = Math.round(your_workout_time / 60)
            return minutes
            
        },

        async fetchgetProgressChallengeWeeks(challengeUUID){ 

            ChallengeService.getProgressChallengeWeeks(challengeUUID)
                .then((response) => {

                    this.challengeDetails = response.data.challenge
                    this.weeks = response.data.weeks

                    console.log(response.data)
                    
                }).catch((err) => {
                    
                    console.log(err) 

                });


        },

        async fetchAWeekWithDaysForProgressReport(weekId){

            ChallengeService.getAWeekWithDaysForProgressReport(weekId)
                .then((response) => {

                    this.userChallengeHistory = response.data.userChallengeHistory
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        async deleteChallengeHistoryRecord(uuid){

            ChallengeService.deleteChallengeHistory(uuid)
                    .then((response) => {

                        console.log(response.data)
                        
                    }).catch((err) => {

                        console.log(err)
                        
                    });

        }



    

        

    }

})