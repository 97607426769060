import apiClient from "./MyClient";


export default{ 

    
    registerUser(formData){
        return apiClient.post('api/web/auth/user/register', formData);
    },

    loginUser(formData){
        return apiClient.post('api/web/auth/user/login', formData);
    },

    verfiyAuthCode(formData){
        return apiClient.post('api/web/auth/code/verify', formData);
    },

    logout(){

        //this is the only endping that need to token
        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('api/web/auth/user/logout');
    }

  
}
