<template>
    <div class="container">


        <WorkoutProgressBar />


        <!-- <button @click="workoutPlayerStore.requestAudioPermission">REQUEST AUDIO PERMISSION</button> -->


        <div v-for="(sequence, index) in workoutPlayerStore.workoutSequence" :key="index">

            <div v-if="workoutPlayerStore.videoIndex == index">

                <div v-if="sequence.exercise_details.type === 'Exercise'">

                    <div v-if="workoutPlayerStore.isPortrait">
                        <h2 class="exercieName">{{ sequence.exercise_details.name }}</h2>
                    </div>

                    <!-- <button @click="startNow()">START</button> <br><br><br> -->

                    <center>

                        <div v-if="!workoutPlayerStore.isPortrait">
                            <h5 class="exercieNameLan">{{ sequence.exercise_details.name }}</h5>
                        </div>

                        <div :class="workoutPlayerStore.isPortrait ? 'row videoWrapper' : ' row videoWrapperLandscape'">

                            <!-- <video class="mainVideo" 
                            :src="sequence.exercise_details.video_url" 
                            type="video/mp4" 
                            width="100%"
                            muted="true"
                            autoplay="true" 
                            loop="true" 
                            playsinline="true"
                            controlsList="nodownload"
                            >
                            </video> -->
                            <!-- <video class="mainVideo" 
                            id="thisvideo"
                            :src="sequence.exercise_details.video_url" 
                            type="video/mp4" 
                            width="100%"
                            muted="true"
                            autoplay
                            loop="true" 
                            playsinline="true"
                            controlsList="nodownload"
                            >
                            </video> -->



                            <video
                            class="mainVideo" 
                            width="100%"
                            :on-change="fuckingPlay"
                            id="thisvideo"
                            ref="myVideo"
                            :src="sequence.exercise_details.video_url" 
                            autoplay 
                            preload="auto" 
                            loop playsinline muted>

                                <source type="video/mp4">

                            </video>

                        </div>

                        <div v-if="workoutPlayerStore.currentplayerControl == 1">

                            <div v-if="!workoutPlayerStore.isPortrait">

                                <span class="timerTextLan">{{ workoutPlayerStore.previewTimerCount }}</span>

                            </div>

                            <div v-show="workoutPlayerStore.isPortrait">

                                <span class="timerTextPotrait">{{ workoutPlayerStore.previewTimerCount }}</span>

                            </div>

                        </div>

                        <div v-if="workoutPlayerStore.currentplayerControl == 2">




                            <div v-if="!workoutPlayerStore.isPortrait">

                                <span class="timerTextLan">{{ workoutPlayerStore.workoutTimerCount }}</span>

                            </div>

                            <div v-show="workoutPlayerStore.isPortrait">

                                <span class="timerTextPotrait">{{ workoutPlayerStore.workoutTimerCount }}</span>

                            </div>



                        </div>



                    </center>

                </div>

                <div v-else>

                    <!-- <div v-if="workoutPlayerStore.isPortrait">
                            <h2 class="exercieName">{{ sequence.exercise_details.name }}</h2>
                        </div> -->

                    <center>

                        <!-- <div v-if="!workoutPlayerStore.isPortrait">
                                <h5 class="exercieNameLan">REST</h5>
                            </div> -->

                        <div :class="workoutPlayerStore.isPortrait ? 'row videoWrapper' : ' row videoWrapperLandscape'">

                            <center>
                                <h2 class="restHeaderText">REST</h2>
                            </center>

                        </div>

                        <div v-if="workoutPlayerStore.currentplayerControl == 3">

                            <div v-if="!workoutPlayerStore.isPortrait">


                                <h1 class="restTimerText">{{ workoutPlayerStore.restTimerCount }}</h1>

                                <img :src="require(`@/assets/rest.gif`)" width="200" />

                            </div>

                            <div v-show="workoutPlayerStore.isPortrait">

                                <h1 class="restTimerText">{{ workoutPlayerStore.restTimerCount }}</h1>

                                <img :src="require(`@/assets/rest.gif`)" width="200" />

                            </div>

                        </div>


                    </center>


                </div>

            </div>


        </div>


        <div v-if="workoutPlayerStore.workoutSequence.length == workoutPlayerStore.videoIndex">

            <h2>END OF WORKOUT</h2>

        </div>


        <BottomNavForPlayer />


        <!-- start workout modal -->
        <div>
            <div class="modale" :class="{ 'opened': workoutPlayerStore.isModalOpen }">

                <button id="tapToStartBtn" @click="workoutPlayerStore.closeModalAndStartMainTimer">TAP TO START
                    WORKOUT</button>

            </div>
        </div>

        <!-- end of workout modal -->
        <div>
            <div class="endmodale" :class="{ 'opened': workoutPlayerStore.endOfWorkoutmodal }">


                <div class="mainWrapper">

                    <div v-if="workoutPlayerStore.isWorkoutSuccessfullyLogged">

                        <h3>Your workout was successfully logged!</h3>

                        <div>
                            <button @click="workoutPlayerStore.redirectFromChallengeWorkout()"
                                class="markWorkoutComplete">TAP HERE TO FINISH</button>
                        </div>

                    </div>

                    <div v-else>

                        <h3>Completed</h3>

                        <img :src="require(`@/assets/checkmark.png`)" width="30" />

                        <div style="margin-top: 20px;">
                            <p>
                                You've completed {{ workoutPlayerStore.exercisesCount }} exercises in 1 minutes. Great Job!
                            </p>

                            <div class="row">

                                <div class="col" style="font-size: 13px;">

                                    <img :src="require(`@/assets/time.png`)" width="16" /> <br>
                                    {{ workoutPlayerStore.getTotalMinsWorkout() }} Minutes

                                </div>

                                <div class="col" style="font-size: 13px;">
                                    <img :src="require(`@/assets/exer.png`)" width="20" /> <br>
                                    {{ workoutPlayerStore.exercisesCount }} Exercises
                                </div>

                                <div class="col" style="font-size: 13px;">
                                    <img :src="require(`@/assets/fire.png`)" width="16" /> <br>
                                    {{ workoutPlayerStore.estimatedCalorieBurned() }} Calories
                                </div>

                            </div>

                            <button @click="workoutPlayerStore.markChallengeWorkotAsComplete()"
                                class="markWorkoutComplete">MARK THIS WORKOUT COMPLETE</button>

                        </div>

                    </div>

                </div>


            </div>
        </div>



    </div>
</template>

<script>
// /* eslint-disable */
import { onMounted, watch } from 'vue'
// import NavForWorkoutPlayer from '../../components/NavForWorkoutPlayer.vue'
import WorkoutProgressBar from '../../components/WorkoutProgressBar.vue'
import BottomNavForPlayer from '../../components/BottomNavForPlayer.vue'
import { useWorkoutPlayerStore } from '../../stores/WorkoutPlayerStore'
// import restAudio from '@/assets/audio/rest_audio.mp3';

export default {
    name: 'WorkoutPlayer',

    components: {
        WorkoutProgressBar,
        BottomNavForPlayer,

    },

    props: ['dayId'],


    setup(props) {

        const workoutPlayerStore = useWorkoutPlayerStore()

        //fetch workout routine
        workoutPlayerStore.fetchWorkoutSequenceForPlayer(props.dayId)


        // const videoSrc = ref('https://femniqe.nyc3.cdn.digitaloceanspaces.com/exercise_videos/alternating%20lower%20legs%20final.mp4');

        // //start the main timer
        // workoutPlayerStore.startMainTimer()


        const currentOrientation = () => {

            window.matchMedia("(orientation: portrait)").addEventListener('change', e => {

                const portrait = e.matches;

                if (portrait) {

                    workoutPlayerStore.isPortrait = true
                    console.log("PORTATIO")

                } else {
                    workoutPlayerStore.isPortrait = false
                    console.log("LANDSCAPE")
                }

            });

        }
        currentOrientation()


        // var startAudio = () => {

        //     const audio = document.getElementById('currentAudioEl');
        //     audio.play()

        // }


        var startNow = () => {

            workoutPlayerStore.startMainTimer()

            // startAudio()

            // const video = document.getElementById('thisvideo');
            // video.play()
            // console.log(video)

        }

        


        onMounted(() => {

            watch(
                () => workoutPlayerStore.currentAudio,
                () => {
                    console.log('AUDIOOO00');
                }
            )
            
        });





        return {
            workoutPlayerStore, startNow,
        }

    }

};
</script>

<style scoped>
.container {
    max-width: 100% !important;
}



.exercieName {
    font-size: 25px;
    font-weight: bold;
}

.exercieNameLan {
    font-size: 25px;
    font-weight: bold;
}

.mainVideo {
    padding: 0px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.videoWrapper {
    max-width: 800px;
    padding: 0px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 50px;
}

.videoWrapperLandscape {
    max-width: 500px;
}

/* @media only screen and (max-width: 300px) {
  .videoWrapper {
    width: 200px;
  }
} */

.timerTextLan {
    font-weight: bolder;
    font-size: 50px;
    position: absolute;
    left: 10px;
    top: 30px;
    z-index: 1;
    /* position: relative;
    z-index: 9998!important; */
}

.timerTextPotrait {
    font-weight: bolder;
    font-size: 50px;
}

.timerText {
    font-weight: bolder;
    font-size: 50px;
}

.modale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}


.modale.opened {

    visibility: visible;
    opacity: 0.91;
    background-color: rgb(0, 0, 0);

}

.endmodale {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(90deg, #fba2c6 0%, #a3ffeb 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.endmodale.opened {

    visibility: visible;
    background-color: rgb(0, 0, 0);

}


#tapToStartBtn {

    width: 70%;
    height: 50px;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;

}

.restHeaderText {
    font-weight: bolder;
    font-size: 45px;
}

.restTimerText {
    font-weight: bolder;
    font-size: 55px;
}

.mainWrapper {
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 700px;
}

.markWorkoutComplete {

    font-size: 14px;
    background-color: black;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-weight: bold;
    border: none;
    width: 100%;
    margin-top: 19px;

}
</style>