<template>
    <div>

        <!-- <NavBar /> -->

        <div class="container">


            <div class="wrapper">



                <div class="row">

                    <div class="col">
                        <h1 id="headerText"><b>{{ challengeStore.selectedChallenge.name }}</b></h1>
                        <p><b>Week {{ challengeStore.week.position + 1 }} - Day {{
                            challengeStore.day.position + 1
                        }}</b></p>
                    </div>

                </div>


                <center>
                    <div class="row descWrapper">

                        <div class="col">

                            <div class="divStyle">

                                <div class="row">

                                    <div class="col">
                                        <div>
                                            <img :src="require(`@/assets/time.png`)" width="15" class="" />
                                        </div>
                                        <div>
                                            {{ challengeStore.workout.full_workout_time }}

                                        </div>
                                    </div>

                                    <div class="col">
                                        <div>
                                            <img :src="require(`@/assets/fire.png`)" width="15" class="" />
                                        </div>
                                        <div>
                                            {{ challengeStore.workout.estimated_calorie_burn }} Calories
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <!-- uuid: {{ challengeStore.selectedChallenge.uuid }} <br> -->


                            <div v-if="challengeStore.isUserEnrolled == 1">

                                <router-link :to="{ name: 'WorkoutPlayer', params: { slug: slug, dayId: dayId } }">
                                    <button class="startBtn">Start Workout</button>
                                </router-link>

                            </div>

                            <div v-else>

                                <div v-if="challengeStore.isLoading">
                                    <button class="startBtn">LOADING...</button>
                                </div>
                                <div v-else>
                                    <button @click="challengeStore.enrollUsertToChallenge(dayId)" class="enrollBtn">Join This Challenge</button>
                                </div>

                            </div>





                        </div>



                    </div>



                    <div v-for="(round, index) in challengeStore.rounds" :key="index">

                        <div class="row rowWrapper">

                            <div class="col">
                                <p class="roundTitle">{{ round.name }}</p>
                            </div>


                            <div v-for="(sequence, index) in round.round_sequence" :key="index" class="exerciseRow">


                                <div v-if="sequence.exercise_details.type === 'Exercise'"
                                    @click="setCurrentExercise(sequence.exercise_details)">

                                    <div class="row exerciseName">

                                        {{ sequence.exercise_details.name }} - {{ sequence.workout_time }} seconds

                                    </div>

                                    <div class="row">

                                        <div class="col-8">
                                            <img class="exerciseIMG" :src="sequence.exercise_details.image_url" />
                                        </div>

                                        <div class="col-4">
                                            <img :src="require(`@/assets/play.png`)" width="15" class="playIcon" />
                                        </div>

                                    </div>


                                </div>

                                <div v-else>



                                    <div class="row" style="padding-left: 10px;">

                                        <div class="col-8">

                                            <div class="restTite">
                                                {{ sequence.exercise_details.name }} - {{ sequence.rest_time }} seconds
                                            </div>


                                        </div>

                                        <div class="col-4">
                                            <img style="float: right; margin-right: 9px;"
                                                :src="require(`@/assets/rest.png`)" width="15" />
                                        </div>

                                    </div>

                                </div>




                            </div>


                        </div>

                    </div>




                </center>


            </div>

            <!-- Modal -->
            <div class="modal fade" id="previewExerciseModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ currentExercise.name }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">

                            <video class="previewVideo" :src="currentExerciseVideoURL" type="video/mp4" muted width="100%"
                                autoplay loop playsinline controlsList="nodownload">
                            </video>


                        </div>



                    </div>
                    
                </div>
            </div>

        </div>


        <Footer />

    </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { ref } from 'vue';
import { Modal } from 'bootstrap'
import { useChallengeStore } from '@/stores/ChallengeStore';
import { useAuthStore } from '@/stores/AuthStore';



export default {

    name: 'ViewADayinChall',
    components: {
        // NavBar, 
        Footer,
    },

    props: ['slug', 'dayId'],


    setup(props) {

        const challengeStore = useChallengeStore()
        const authStore = useAuthStore()

        //fetch day workout details
        challengeStore.fetchDayWorkoutDetails(props.dayId)

        //check url to toggle arrow
        authStore.checkRoute()

        var currentExercise = ref({})
        var currentExerciseVideoURL = ref('')


        var showPreviewExerciseModal = () => {

            var exerciseModal = new Modal(document.getElementById('previewExerciseModal'), {
                keyboard: false
            })

            exerciseModal.show()
        }

        var setCurrentExercise = (exercise) => {

            currentExercise.value = exercise
            currentExerciseVideoURL.value = exercise.video_url


            showPreviewExerciseModal()
        }




        return {
            challengeStore, currentExerciseVideoURL, showPreviewExerciseModal, setCurrentExercise, currentExercise
        }

    }


};
</script>

<style scoped>
.container {
    margin-top: 110px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    font-size: 19px;
}


.challImg {
    border-radius: 20px;
    width: 500px;
    margin-top: 10px;
}

.wrapper {
    width: 100%;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.subHeader {
    font-size: 15px;
    padding-top: 10px;
}

.descWrapper {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.weekWrapper {
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dayItem {
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: white;
    border-bottom: 1px solid #f3f4f4;
}




.divStyle {
    font-size: 15px;
    /* background-color: #ccf7cc; */
    border-radius: 5px;

}

.startBtn {
    color: white;
    font-weight: bold;
    background-color: #ea55a0;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.enrollBtn{

    color: white;
    font-weight: bold;
    background-color: #030102;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.roundTitle {
    float: left;
    margin-bottom: 4px;
}

.rowWrapper {
    max-width: 500px;
    margin-bottom: 15px;
}

.exerciseRow {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
}

.exerciseIMG {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    float: left;
    max-width: 80px;
}

.playIcon {
    float: right;
    /* padding-top: 20px; */
    margin-right: 10px;
}

.exerciseName {
    font-size: 13px;
    padding-left: 10px;
    padding-bottom: 4px;
}

.previewVideo {
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.restTite {
    font-size: 13px;
    /* padding-left: 10px;
    padding-bottom: 4px; */
    float: left;
}
</style>