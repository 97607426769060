<template>
    <div>


        <div class="container">


            <div class="wrapper">



                <div class="row">

                    <div class="col">
                        <h1 id="headerText"><b>{{ workoutStore.normaWorkoutDetails.name }}</b></h1>

                    </div>

                </div>


                <center>
                    <div class="row descWrapper">

                        <div class="col">

                            <div class="divStyle">

                                <div class="row">

                                    <div class="col">
                                        <div>
                                            <img :src="require(`@/assets/time.png`)" width="15" />
                                        </div>
                                        <div>
                                            {{ workoutStore.normaWorkoutDetails.full_workout_time }}

                                        </div>
                                    </div>

                                    <div class="col">
                                        <div>
                                            <img :src="require(`@/assets/fire.png`)" width="15" class="" />
                                        </div>
                                        <div>
                                            {{ workoutStore.normaWorkoutDetails.estimated_calorie_burn }} Calories
                                        </div>
                                    </div>


                                </div>

                            </div>


                            <div v-if="!authStore.is_subscribed && workoutStore.normaWorkoutDetails.is_workout_pro === 1">



                                <button class="startBtn" type="button" @click="showPayWallModal">
                                    <img class="lockIcon" :src="require(`@/assets/lock.png`)" width="15" />
                                    Unlock This Workout
                                </button>

                            </div>
                            <div v-else>

                                <router-link :to="{ name: 'NormalWorkoutPlayer', params: { slug: slug } }">
                                    <button class="startBtn">Start Workout</button>
                                </router-link>

                            </div>





                        </div>



                    </div>


                    <div v-if="!authStore.is_subscribed && workoutStore.normaWorkoutDetails.is_workout_pro === 1">

                        <img :src="require(`@/assets/thisblur.png`)" class="blurImage" /> <br>

                        <img :src="require(`@/assets/thisblur.png`)" class="blurImage" />

                    </div>
                    <div v-else>

                        <div v-for="(round, index) in workoutStore.rounds" :key="index">

                            <div class="row rowWrapper">

                                <div class="col">
                                    <p class="roundTitle">{{ round.name }}</p>
                                </div>


                                <div v-for="(sequence, index) in round.round_sequence" :key="index" class="exerciseRow">


                                    <div>

                                    </div>


                                    <div v-if="sequence.exercise_details.type === 'Exercise'"
                                        @click="setCurrentExercise(sequence.exercise_details)">

                                        <div class="row exerciseName">

                                            {{ sequence.exercise_details.name }} - {{ sequence.workout_time }} seconds

                                        </div>

                                        <div class="row">

                                            <div class="col-8">
                                                <img class="exerciseIMG" :src="sequence.exercise_details.image_url" />
                                            </div>

                                            <div class="col-4">
                                                <img :src="require(`@/assets/play.png`)" width="15" class="playIcon" />
                                            </div>

                                        </div>


                                    </div>

                                    <div v-else>



                                        <div class="row">

                                            <div class="col-8">

                                                <div class="restTite">
                                                    {{ sequence.exercise_details.name }} - {{ sequence.rest_time }} seconds
                                                </div>


                                            </div>

                                            <div class="col-4">
                                                <img style="float: right; margin-right: 9px;"
                                                    :src="require(`@/assets/rest.png`)" width="15" />
                                            </div>

                                        </div>

                                    </div>




                                </div>


                            </div>

                        </div>

                    </div>






                </center>


            </div>

            <!-- Modal -->
            <div class="modal fade" id="previewExerciseModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ currentExercise.name }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">

                            <video class="previewVideo" :src="currentExerciseVideoURL" type="video/mp4" muted width="100%"
                                autoplay loop playsinline controlsList="nodownload">
                            </video>


                        </div>



                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="payWallModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">

                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        <h5 class="modal-title" id="exampleModalLabel">Change starts today</h5>
                        <h6>A better version of you awaits on the horizon</h6>



                        <div class="modal-body">


                            <div class="row">
                                <p class="payBodyText">Upgrade and unlock access to:</p>
                            </div>


                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        All body transforming challenges from glutes, thighs, waist to upper body
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Progress reports to track your progress
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        New workout sequences and challenges added every month
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Access to all features
                                    </div>
                                </div>

                            </div>



                            <button type="button" class="buyBtn"> 1 Month $9.99 </button>
                            <button type="button" class="buyBtn">
                                1 Year $3/Month (Best Value)
                                $35.99

                            </button>
                            <button type="button" class="buyBtn"> 3 Month $19.99 </button>



                        </div>



                    </div>

                </div>
            </div>

        </div>


        <Footer />


    </div>
</template> 

<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { ref } from 'vue';
import { Modal } from 'bootstrap'
import { useWorkoutPlayerStore } from '../../stores/WorkoutPlayerStore';
import { useAuthStore } from '@/stores/AuthStore';

export default {

    name: 'ViewNormalWorkout',
    components: {
        Footer,
        // NavBar
    },

    props: ['slug'],


    setup(props) {

        const workoutStore = useWorkoutPlayerStore()
        const authStore = useAuthStore()

        //fetch workout details
        workoutStore.fetchNormalWorkoutDetails(props.slug)

        //check url to toggle arrow
        authStore.checkRoute()

        var currentExercise = ref({})
        var currentExerciseVideoURL = ref('')


        var showPreviewExerciseModal = () => {

            var exerciseModal = new Modal(document.getElementById('previewExerciseModal'), {
                keyboard: false
            })

            exerciseModal.show()
        }

        var setCurrentExercise = (exercise) => {

            currentExercise.value = exercise
            currentExerciseVideoURL.value = exercise.video_url


            showPreviewExerciseModal()
        }

        var showPayWallModal = () => {

            var payWallModal = new Modal(document.getElementById('payWallModal'), {
                keyboard: false
            })

            payWallModal.show()
        }


        return {
            workoutStore, authStore, currentExerciseVideoURL, showPreviewExerciseModal, setCurrentExercise, currentExercise, showPayWallModal
        }

    }


};
</script>

<style scoped>
.container {
    margin-top: 110px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    font-size: 19px;
}


.challImg {
    border-radius: 20px;
    width: 500px;
    margin-top: 10px;
}

.wrapper {
    width: 100%;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.subHeader {
    font-size: 15px;
    padding-top: 10px;
}

.descWrapper {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.weekWrapper {
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dayItem {
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: white;
    border-bottom: 1px solid #f3f4f4;
}




.divStyle {
    font-size: 15px;
    /* background-color: #ccf7cc; */
    border-radius: 5px;

}

.startBtn {
    color: white;
    font-weight: bold;
    background-color: #ea55a0;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.roundTitle {
    float: left;
    margin-bottom: 4px;
}

.rowWrapper {
    max-width: 500px;
    margin-bottom: 15px;
}

.exerciseRow {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;

}

.exerciseIMG {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    float: left;
    max-width: 80px;

}

.playIcon {
    float: right;
    /* padding-top: 20px; */
    margin-right: 10px;
}

.exerciseName {
    font-size: 13px;
    padding-left: 10px;
    padding-bottom: 4px;

}

.exerciseNameBlurred {
    font-size: 13px;
    padding-left: 10px;
    padding-bottom: 4px;
    filter: blur(7px);
    -webkit-filter: blur(5px);
}

.exerciseIMGBlurred {
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    float: left;
    max-width: 80px;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.restTextBlurred {
    /* font-size: 13px; */
    filter: blur(7px);
    -webkit-filter: blur(5px);
}

.previewVideo {
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.restTite {
    font-size: 13px;
    /* padding-left: 10px;
    padding-bottom: 4px; */
    float: left;
}

.lockIcon {
    margin-top: -4px;
}

.blurImage {
    max-width: 300px;
    opacity: 0.37;
}

.modal-content {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%) !important;
}

.payBodyText {
    float: left;
    font-size: 16px;
}

.listWrapper {
    margin-bottom: 5px;
}

.buyBtn{
    color: white;
    font-weight: bolder;
    /* font-size: 20px; */
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
    border: none;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}


.btn-close {
    padding: 10px;
    font-size: 10px;
}

.iconWords {
    float: left;
    text-align: left;
}

.checkIcon {
    float: left;
}

</style>