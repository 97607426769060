<template>
    <div>
        <div class="container">



            <div class="row">

                <center>
                    <div class="row" id="pills-tab" role="tablist">

                        <div class="col">

                            <div class="" role="presentation">

                                <button style="width:100%" class="tabBtn active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">
                                    My Profile
                                </button>
                            </div>

                        </div>

                        <div class="col">

                            <div class="" role="presentation">

                                <button style="width:100%" class="tabBtn" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">
                                    Billing
                                </button>
                            </div>

                        </div>




                    </div>
                </center>

                <div class="tab-content" id="pills-tabContent">

                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                        <div class="row">


                            <center>
                                <div class="mainWrapper">


                                    <div class="row wrapper">

                                        <form>

                                            <div class="mb-3">

                                                <label class="form-label">Name</label>
                                                <input type="text" class="form-control" required>

                                            </div>

                                            <div class="mb-3">

                                                <label class="form-label">Display Name</label>
                                                <input type="text" class="form-control" placeholder="Display Name">

                                            </div>

                                            <div class="mb-3">

                                                <label class="form-label">Email</label>
                                                <input type="email" class="form-control" required>

                                            </div>




                                            <div class="mb-3">


                                                <p style="text-align: left;">Your Profile Avatar</p>

                                                <div @click="selectFile" class="profilePicWrapper">


                                                    <img v-if="filePreview" class="previewImg" :src="filePreview"
                                                        alt="Uploaded image">
                                                    <img v-else class="uploadpic" :src="require(`@/assets/userava.png`)" />



                                                    <input type="file" accept="image/*" ref="fileInput"
                                                        style="display: none" @change="onFileSelected">

                                                </div>


                                            </div>


                                            <div class="mt-5">
                                                <button type="submit" class="btn btn-success"><b>Save Changes</b></button>
                                            </div>



                                        </form>


                                    </div>


                                </div>
                            </center>


                        </div>


                    </div>


                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                        <center>
                            <div class="billingMainWrapper">

                                <p class="billingHeader">My Femniqe Pro Membership</p>

                                <p>You do not currently have an active subscription.</p>


                                <div v-if="authStore.is_subscribed === 1">

                                    <button @click="authStore.cancelSubscription()" type="button"
                                    class="cancelBtn">Cancel</button>

                                </div>
                                <div v-else>

                                    <button @click="showPayWallModal" type="button" class="upgradeBtn">Upgrade Now</button>

                                </div>


                                



                                




                            </div>
                        </center>


                    </div>

                </div>

            </div>


            <!-- Modal -->
            <div class="modal fade" id="payWallModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">

                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        <h5 class="modal-title" id="exampleModalLabel">Change starts today</h5>
                        <h6>A better version of you awaits on the horizon</h6>



                        <div class="modal-body">


                            <div class="row">
                                <p class="payBodyText">Upgrade and unlock access to:</p>
                            </div>


                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        All body transforming challenges from glutes, thighs, waist to upper body
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Progress reports to track your progress
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        New workout sequences and challenges added every month
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Access to all features
                                    </div>
                                </div>

                            </div>


                            <div v-for="(product, index) in paddleProducts" :key="index">

                                <button @click="setSelectedProductId(product.paddle_id)" type="button" class="buyBtn"> {{
                                    product.name }} ${{ product.price }} </button>

                            </div>




                        </div>



                    </div>

                </div>
            </div>



        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/AuthStore';
import { Modal } from 'bootstrap'


export default {
    name: "MyAccount",



    setup() {

        const authStore = useAuthStore()

        const file = ref(null);
        const filePreview = ref(null);

        const selectFile = () => {
            const fileInput = document.querySelector('input[type="file"]');
            fileInput.click();
        }

        const onFileSelected = (event) => {

            file.value = event.target.files[0];
            console.log('Selected file:', file.value);

            // Create a preview URL for the selected image
            const reader = new FileReader();
            reader.onload = (e) => {
                filePreview.value = e.target.result;
            };
            reader.readAsDataURL(file.value);
        }

        var showPayWallModal = () => {

            var payWallModal = new Modal(document.getElementById('payWallModal'), {
                keyboard: false
            })

            payWallModal.show()
        }

        var hidePayWallModal = () => {

            var weekModal = document.getElementById('payWallModal')
            var modal = Modal.getOrCreateInstance(weekModal)

            modal.hide()

        }

        //payment logic below
        const paddleProducts = [
            { id: 1, name: '1 Month', price: 9.99, paddle_id: 46343 },
            { id: 2, name: '3 Month', price: 19.99, paddle_id: 46346 },
            { id: 3, name: '1 Year', price: 35.99, paddle_id: 46347 },
        ]

        var selectedProductId = ref(0)

        /* eslint-disable */
        var setSelectedProductId = (product_id) => {

            selectedProductId.value = product_id

            var checkoutConfig = {
                product: selectedProductId.value,
                email: authStore.email,
                passthrough: {
                    billable_id: authStore.user_id,
                    billable_type: 'App\\Models\\User'
                },

                successCallback: function (data) {

                    updateIsSubscribed()
                    hidePayWallModal()
                    console.log('Purchase was successful!');

                },
            };

            Paddle.Environment.set('sandbox');
            Paddle.Checkout.open(checkoutConfig);

            console.log(checkoutConfig)


        }


        const updateIsSubscribed = () => {

            authStore.is_subscribed = 1

        }

        onMounted(() => {
            Paddle.Setup({ vendor: 11037 }); // Replace with your own vendor ID
        });

        return {
            authStore, file, selectFile, onFileSelected, filePreview, showPayWallModal, paddleProducts, setSelectedProductId
        }

    }
};
</script>

<style scoped>
.container {
    margin-top: 80px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    float: left;
    font-size: 17px;
}

.form-label {
    float: left;
}

.mainWrapper {
    max-width: 400px;
    background: rgb(229, 239, 255);
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
}

.billingMainWrapper {
    max-width: 400px;
    background: rgb(229, 239, 255);
    padding: 20px;
    border-radius: 20px;
    margin-top: 20px;
}



.profilePicWrapper {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}


.uploadpic {
    max-width: 60px;
}

.previewImg {
    max-width: 100px;
    border-radius: 10px;
}

.tabBtn {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    color: rgb(13, 4, 4);
    font-size: 16px;
    padding: 10px;
    border: none;
}


.billingHeader {
    font-weight: bold;
}


.upgradeBtn {
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
    border: none;
    padding: 10px;
    color: white;
    font-weight: bold;
}


.cancelBtn {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    background: rgb(201, 201, 201);
    border: none;
    padding: 10px;
    color: rgb(57, 57, 57);
    font-weight: bold;
}


.buyBtn {
    color: white;
    font-weight: bolder;
    /* font-size: 20px; */
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
    border: none;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}



.payBodyText {
    float: left;
    font-size: 16px;
}


.checkIcon {
    float: left;
}

.iconWords {
    float: left;
    text-align: left;
}

.listWrapper {
    margin-bottom: 5px;
}

.modal-content {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%) !important;
}

.btn-close {
    padding: 10px;
    font-size: 10px;
}

</style>