<template>
    <div>

        <div class="header" id="myHeader">

            <div class="row">
                <div class="col">



                    <div v-if="authStore.showGoBackArrow">

                        <img @click="$router.back()" :src="require(`@/assets/arrow.png`)" class="backArrow" />

                    </div>
                    <div v-else>

                        <img :src="require(`@/assets/userava.png`)" class="uicon" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample"  />

                    </div>




                </div>

                <div class="col">

                    <a href="/">
                        <img :src="require(`@/assets/fem-logo.png`)" width="60" />
                    </a>

                </div>

                <div class="col">

                </div>
            </div>

        </div>


        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" data-bs-backdrop="false" aria-labelledby="offcanvasExampleLabel">

            <div class="offcanvas-header">
                <h6 class="offcanvas-title" id="offcanvasExampleLabel">Menu</h6>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="offcanvas-body">

                <router-link class="linkStyle" :to="{ name: 'home' }">

                    <div class="row rowDesign">
                        <div class="col-2">
                            <img :src="require(`@/assets/hometab.png`)" class="tabIcon" />
                        </div>
                        <div class="col-10">
                            <span class="tabText">Home</span>
                        </div>
                    </div>

                </router-link>


                <router-link class="linkStyle" :to="{ name: 'ViewAllChallenges' }">

                    <div class="row rowDesign">
                        <div class="col-2">
                            <img :src="require(`@/assets/chall.png`)" class="tabIcon" />
                        </div>
                        <div class="col-10">
                            <span class="tabText">Challenges</span>
                        </div>
                    </div>

                </router-link>


                <router-link class="linkStyle" :to="{ name: 'MyAccount' }">

                    <div class="row rowDesign">
                        <div class="col-2">
                            <img :src="require(`@/assets/account.png`)" class="tabIcon" />
                        </div>
                        <div class="col-10">
                            <span class="tabText">My Account</span>
                        </div>
                    </div>

                </router-link>




                <div class="row rowDesign" @click="authStore.logoutUser">

                    <div class="col-2">
                        <img :src="require(`@/assets/exit.png`)" class="tabIcon" />
                    </div>
                    <div class="col-10">
                        <span class="tabText">Logout</span>
                    </div>

                </div>


            </div>

        </div>

    </div>
</template>

<script>
import { useAuthStore } from '@/stores/AuthStore';



export default {
    name: 'MobileStickyHeader',

    setup() {

        const authStore = useAuthStore()

        // const route = useRoute
        // const currentUrl = computed(() => route.path)

        // const urlsArray = [
        //     '/',
        //     '/workouts',
        //     'progress',
        //     'profile'
        // ]

        // const isUrlMatch = urlsArray.includes(currentUrl);

        // const checkUrl = () => {

        //     if(isUrlMatch){

        //         console.log('match')

        //     }else{

        //         console.log('didnt match')
        //         authStore.showGoBackArrow = true

        //     }
        // }

        // checkUrl()

        return {
            authStore
        }

    }

};
</script>

<style scoped>
.header {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #ffffff;
    color: #040303;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.uicon {
    width: 25px;
    float: left;
}

.offcanvas {
    width: 250px !important;
}

.tabIcon {
    width: 20px;
    float: left;
}

.tabText {
    font-size: 17px;
    float: left;
    font-weight: bold;
}

.rowDesign {
    margin-bottom: 17px;
}


.backArrow {
    float: left;
    width: 15px;
    margin-top: 5px;
}


.linkStyle {
    text-decoration: none;
    color: inherit;
}


.offcanvas{
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
}


</style>