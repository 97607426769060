<template>
    <div>


        <div class="container">


            <div class="row">

                <div class="col">
                    <h2 id="headerText"><b>Challenges</b></h2>
                </div>

            </div>



            <div class="row g-2">

                <div class="col-sm" v-for="(challenge, index) in challengeStore.challengesList" :key="index">


                    <router-link class="linkStyle" :to="{ name: 'ViewChallDetail', params: { slug: challenge.slug } }">

                        <div class="challengeItem">

                            <div class="card">

                                <img :src="challenge.featured_image_url" class="card-img-top">

                                <div class="card-body">
                                    <div class="bodyText">
                                        {{ challenge.name }}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </router-link>



                </div>

            </div>



        </div>


        <Footer />

    </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { useChallengeStore } from '@/stores/ChallengeStore';
useChallengeStore


export default {
    name: 'ViewAllChallenges',
    components: {
        // NavBar,
        Footer,
    },

    setup() {

        const challengeStore = useChallengeStore();

        //fetch all challenges
        challengeStore.fetchAllChallenges()

        return {
            challengeStore
        }

    }


};
</script>

<style scoped>
.container {
    margin-top: 110px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    float: left;
    font-size: 17px;
}

.card {
    border: none !important;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.challengeItem {
    min-width: 200px;
    margin: 5px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


</style>