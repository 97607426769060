<template>
    <div>

        <!-- <NavBar /> -->


        <div class="container">

            <div class="wrapper">

                <img src="https://cdn-icons-png.flaticon.com/512/507/507257.png" width="15px"
                    style="margin-bottom: 10px; float: left;" />

                <div class="row">

                    <div class="col">
                        <h1 id="headerText"><b>{{ challengeStore.selectedChallenge.name }}</b></h1>
                    </div>

                </div>


                <img class="img-fluid challImg" :src="challengeStore.selectedChallenge.featured_image_url">




                <center>
                    <div class="row descWrapper">

                        <h2 class="subHeader"><b>ABOUT THE PROGRAM</b></h2>

                        <p class="text-start bodyText">
                            {{ challengeStore.selectedChallenge.desc }}
                        </p>

                        <div class="col-sm-6">

                            <div class="divStyle">

                                <div class="row">
                                    <div class="col">
                                        At Home
                                        <!-- <img :src="require(`@/assets/home.png`)" width="15" />  -->
                                    </div>

                                </div>
                            </div>



                        </div>

                        <div class="col-sm-6">
                            <div class="divStyle">
                                Euipment: Optional
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="divStyle">
                                10-25 Minutes/session
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="divStyle">
                                {{ challengeStore.selectedChallenge.amt_of_days }} Days
                            </div>
                        </div>


                    </div>
                </center>

                <center>




                    <!-- {{ challengeStore.currentWeekIndex }} / {{ challengeStore.selectedChallengeWeeks.length }} -->


                    <div v-for="(week, index) in challengeStore.selectedChallengeWeeks" :key="index">

                        <div v-if="challengeStore.currentWeekIndex == index">

                            <div class="row weekWrapper">

                                <div class="weekTitleWrapper">
                                    <span class="weekTitle">
                                        Week {{ week.position + 1 }}
                                    </span>
                                </div>



                                <div v-for="(day, index) in week.days" :key="index" class="dayItem">

                                    <div
                                        v-if="!authStore.is_subscribed && challengeStore.selectedChallenge.is_challenge_pro === 1">


                                        <div @click="showPayWallModal">
                                            <div v-if="day.workout.slug == 'rest'">

                                                <div class="row">
                                                    <div class="col-8">


                                                        <span class="dayText"><b>Day {{ day.position + 1 }}</b> - Rest
                                                        </span>
                                                    </div>

                                                    <div class="col-4">
                                                        <img :src="require(`@/assets/next.png`)" width="15"
                                                            class="dayIcon" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div v-else>


                                                <div class="row">
                                                    <div class="col-8">


                                                        <span class="dayText"><b>Day {{ day.position + 1 }}</b> - {{
                                                            day.workout.full_workout_time }} Workout
                                                            Sequence
                                                        </span>
                                                    </div>

                                                    <div class="col-4">
                                                        <img :src="require(`@/assets/next.png`)" width="15"
                                                            class="dayIcon" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div v-else>

                                        <div v-if="day.workout.slug == 'rest'">

                                            <div class="row">
                                                <div class="col-8">


                                                    <span class="dayText"><b>Day {{ day.position + 1 }}</b> - Rest
                                                    </span>
                                                </div>

                                                <div class="col-4">
                                                    <img :src="require(`@/assets/next.png`)" width="15" class="dayIcon" />
                                                </div>
                                            </div>

                                        </div>

                                        <div v-else>

                                            <router-link class="linkStyle"
                                                :to="{ name: 'ViewADayinChall', params: { slug: challengeStore.selectedChallenge.slug, dayId: day.id } }">

                                                <div class="row">
                                                    <div class="col-8">


                                                        <span class="dayText"><b>Day {{ day.position + 1 }}</b> - {{
                                                            day.workout.full_workout_time }} Workout
                                                            Sequence
                                                        </span>
                                                    </div>

                                                    <div class="col-4">
                                                        <img :src="require(`@/assets/next.png`)" width="15"
                                                            class="dayIcon" />
                                                    </div>
                                                </div>

                                            </router-link>

                                        </div>


                                    </div>



                                </div>
                            </div>

                        </div>


                    </div>

                    <center>
                        <div class="row btnWrapper">

                            <div class="col btnstyles" @click="challengeStore.prevWeek">

                                PREV

                            </div>

                            <div class="col btnstyles" @click="challengeStore.nextWeek">

                                NEXT

                            </div>

                        </div>
                    </center>

                </center>


            </div>




            <!-- Modal -->
            <div class="modal fade" id="payWallModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">

                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                        <h5 class="modal-title" id="exampleModalLabel">Change starts today</h5>
                        <h6>A better version of you awaits on the horizon</h6>



                        <div class="modal-body">


                            <div class="row">
                                <p class="payBodyText">Upgrade and unlock access to:</p>
                            </div>


                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        All body transforming challenges from glutes, thighs, waist to upper body
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Progress reports to track your progress
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        New workout sequences and challenges added every month
                                    </div>
                                </div>

                            </div>

                            <div class="row listWrapper">

                                <div class="col-1" style="margin-right: -5px;">
                                    <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="18" />
                                </div>

                                <div class="col-11">
                                    <div class="iconWords">
                                        Access to all features
                                    </div>
                                </div>

                            </div>


                            <div v-for="(product, index) in paddleProducts" :key="index">

                                <button @click="setSelectedProductId(product.paddle_id)" type="button" class="buyBtn"> {{
                                    product.name }} ${{ product.price }} </button>

                            </div>

                            


                        </div>



                    </div>

                </div>
            </div>




        </div>


        <Footer />

    </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { useChallengeStore } from '@/stores/ChallengeStore';
import { useAuthStore } from '@/stores/AuthStore';
import { Modal } from 'bootstrap'
import { onMounted, ref } from 'vue';


export default {

    name: 'ViewChallDetail',

    components: {
        // NavBar,
        Footer,
    },

    props: ['slug'],

    setup(props) {

        const challengeStore = useChallengeStore()
        const authStore = useAuthStore()

        authStore.checkRoute()

        //fetch challenge detail
        challengeStore.fetchChallengeDetails(props.slug)

        var showPayWallModal = () => {

            var payWallModal = new Modal(document.getElementById('payWallModal'), {
                keyboard: false
            })

            payWallModal.show()
        }

        var hidePayWallModal = () => {

            var weekModal = document.getElementById('payWallModal')
            var modal = Modal.getOrCreateInstance(weekModal)

            modal.hide()

        }

        //payment logic below
        const paddleProducts = [
            { id: 1, name: '1 Month', price: 9.99, paddle_id: 46343 },
            { id: 2, name: '3 Month', price: 19.99, paddle_id: 46346 },
            { id: 3, name: '1 Year', price: 35.99, paddle_id: 46347 },
        ]

        var selectedProductId = ref(0)

        /* eslint-disable */
        var setSelectedProductId = (product_id) => {

            selectedProductId.value = product_id
            
            var checkoutConfig = {
                product: selectedProductId.value, 
                email: authStore.email, 
                passthrough: {
                    billable_id: authStore.user_id,
                    billable_type: 'App\\Models\\User'
                },

                successCallback: function(data) {
                 
                    updateIsSubscribed()
                    hidePayWallModal()
                    console.log('Purchase was successful!');
                    
                },
            };

            Paddle.Environment.set('sandbox');
            Paddle.Checkout.open(checkoutConfig);

            console.log(checkoutConfig)


        }

      

        const updateIsSubscribed = () => {

            authStore.is_subscribed = 1

        }

        onMounted(() => {
            Paddle.Setup({ vendor: 11037 }); // Replace with your own vendor ID
        });


        return {
            authStore, challengeStore, showPayWallModal, paddleProducts, setSelectedProductId
        }


    }


};
</script>

<style scoped>
.container {
    margin-top: 110px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    font-size: 19px;
}


.challImg {
    border-radius: 20px;
    width: 500px;
    margin-top: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrapper {
    width: 100%;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.subHeader {
    font-size: 15px;
    padding-top: 10px;
}

.descWrapper {
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.bodyText {
    font-size: 16px;
}

.weekWrapper {
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dayItem {
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: white;
    border-bottom: 1px solid #f3f4f4;
}

.dayItem:hover {
    background-color: rgb(230, 237, 239);
}

.weekTitleWrapper {
    padding: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #f0f8ff;
    /* margin-bottom: 10px; */
}

.weekTitle {
    font-size: 22px;
}

.dayText {
    font-size: 14px;
    float: left;
}


.dayIcon {
    float: right;
}

.btnWrapper {
    background-color: rgb(227, 214, 255);
    max-width: 500px;
}

.btnstyles {
    color: rgb(0, 0, 0);
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: solid;
    border-color: white;
}


.divStyle {
    font-size: 15px;
    background-color: #ccf7cc;
    /* max-width: 200px; */
    border-radius: 5px;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.iconText {
    margin-bottom: 50px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}

.modal-content {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%) !important;
}

.btn-close {
    padding: 10px;
    font-size: 10px;
}

.modal-title {
    font-weight: bolder;
}

.contentWrapper {
    margin-top: 10px;
    max-width: 450px;
    background-color: #ccf7cc;
}

.payBodyText {
    float: left;
    font-size: 16px;
}


.checkIcon {
    float: left;
}

.iconWords {
    float: left;
    text-align: left;
}

.listWrapper {
    margin-bottom: 5px;
}


.buyBtn {
    color: white;
    font-weight: bolder;
    /* font-size: 20px; */
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
    border: none;
    padding: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
</style>