<template>
    <div>



        <nav class="mynav">

            <router-link class="nav__link" :to="{ name: 'home' }">

                <img :src="require(`@/assets/hometab.png`)" class="tabIcon" />
                <span class="nav__text">Home</span>

            </router-link>

            <router-link class="nav__link" :to="{ name: 'WorkoutsList' }">

                <img :src="require(`@/assets/dumbell.png`)" class="tabIcon" />
                <span class="nav__text">Workouts</span>

            </router-link>

            <router-link class="nav__link" :to="{ name: 'ProgressReports' }">

                <img :src="require(`@/assets/stats.png`)" class="tabIcon" />
                <span class="nav__text">Progress</span>

            </router-link>

            <router-link class="nav__link" :to="{ name: 'MyAccount' }">

                <img :src="require(`@/assets/setting.png`)" class="tabIcon" />
                <span class="nav__text">Account</span>

            </router-link>

            <!-- <a href="/" class="nav__link">
                <img :src="require(`@/assets/hometab.png`)" class="tabIcon" />
                <span class="nav__text">Home</span>
            </a> -->


            <!-- <a href="/workouts" class="nav__link">
                <img :src="require(`@/assets/dumbell.png`)" class="tabIcon" />
                <span class="nav__text">Workouts</span>
            </a>
            <a href="/progress" class="nav__link">
                <img :src="require(`@/assets/stats.png`)" class="tabIcon" />
                <span class="nav__text">Progress</span>
            </a>

            <a href="/profile" class="nav__link">
                <img :src="require(`@/assets/setting.png`)" class="tabIcon" />
                <span class="nav__text">Account</span>
            </a> -->

        </nav>



    </div>
</template>

<script>
export default {
    name: 'MobileBottomNavBar',


};
</script>

<style >
.mynav {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 100px; */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
    padding: 10px;
}

.nav__link {
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

/* .nav__link:hover {
    background-color: #eeeeee;
} */

.nav__link--active {
    color: #009578;
}

.nav__icon {
    font-size: 18px;
}

.nav__text {
    font-size: 9px;
}

.tabIcon {
    width: 15px;
}

/*  */
/* 
.navlink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif; 
    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;

} */
</style>