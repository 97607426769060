
import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'
 

export default{ 


    async getDataForHomePage(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenges');
    },

    async getAChallenge(slug){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenge/' + slug);
    },

    async getAllChallengesList(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenges/list');

    },

    async getADayWorkoutDetails(dayId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenge/workout/day/' + dayId);
    },


    async enrollUserToThisChallenge(challengeUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token
        
        return apiClient.post('api/web/enroll/user/challenge/' + challengeUUID)
    },

    async getAllChallengesUserStarted(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenges/history/list');

    },

    async getProgressChallengeWeeks(challengeUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/progressreport/challengeweeks/' + challengeUUID);

    },

    async getAWeekWithDaysForProgressReport(weekId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/progressrepot/weekwithdays/' + weekId);

    },


    async deleteChallengeHistory(challengeUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/web/challenge/history/delete/' + challengeUUID);


    }





    


    

}