/* eslint-disable */ 
import { defineStore } from 'pinia'
import AuthService from '@/services/AuthService.js'
import apiClient from "../services/MyClient";
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import BillingService from '@/services/BillingService';



export const useAuthStore = defineStore('authStore', {

    state: () => ({

        registerFormData: {
            name: '', 
            email: '',
            password: '',
            password_confirmation: ''
        },

        email: '',
        user_id: '',
        token: '',
        is_subscribed: 0, //check if user is paid or free

        isUserLoggedIn: false,

        doesPasswordMatch: false,

        loginFormData: {
            email: '',
            password: '',
        },

        authCode: '',
        isLoading: false,
        errorMessage: '',

        showGoBackArrow: false,
        urlsArray: [
            '/',
            '/workouts',
            '/progress',
            '/profile'
        ],

        

        shouldHideMobilNav: false


    }),

    persist: true, 


    actions: {

        registerUser(){

            this.errorMessage = ''
            this.isLoading = true

            if(this.registerFormData.password != this.registerFormData.password_confirmation){

                this.errorMessage = "Passwords does't match"

                this.isLoading = false

                console.log("Passwords don't match")

            }else{

                apiClient.get('/sanctum/csrf-cookie').then(() => {

                    AuthService.registerUser(this.registerFormData)
                        .then((response) => {
        
                            this.router.push({ name: 'VerifyEmail' })
    
                            console.log(response.data)

                            this.registerFormData.password = ''
                            this.registerFormData.password_confirmation = ''
    
                            this.isLoading = false

             
                            
                        }).catch((err) => {
    
                            this.isLoading = false
                            console.log(err)
                            
                        });
    
                })
                .catch(error => {
    
                    console.log(error)
    
                })

            }

            


        },

        async login(){

            apiClient.get('/sanctum/csrf-cookie').then(() => {

                AuthService.loginUser(this.loginFormData)
                    .then((response) => {


                        this.isLoading = false

                        if (response.data.message === 1){

                            this.errorMessage = "The provided credentials are incorrect"

                        }else{

                    
                            //set token with other info
                            this.email = response.data.user.email
                            this.user_id = response.data.user.id
                            this.token = response.data.token
                            this.is_subscribed = response.data.user.is_subscribed

                            //important: add upate the header with token
                            apiClient.interceptors.request.use((config) => {
                                config.headers.Authorization = `Bearer ${this.token}`
                                return config;
                            })

                            this.router.push({ name: 'home' })

                            this.isUserLoggedIn = true

                            console.log(response.data)

                        }

                        
                    }).catch((err) => {

                        this.isLoading = false
                        console.log(err)
                        
                    });

            })
            .catch(error => {

                console.log(error)

            }) 


        },

        async logoutUser(){

            AuthService.logout() 
                .then((response) => {

                    //remove token from localstorage
                    this.token = ''

                    //clear login data from
                    this.loginFormData.email = ''
                    this.loginFormData.password = ''
                    this.is_subscribed = 0

                    window.localStorage.clear()

                    this.isUserLoggedIn = false
                    
                    this.router.push({name: 'LoginView'})
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        verifyUserAuthCode(){

            this.errorMessage = ''

            const formData = {
                email: this.registerFormData.email,
                auth_code: this.authCode
            }

            AuthService.verfiyAuthCode(formData)
                .then((response) => {

                    if (response.data.message === 1){

                        this.router.push({ name: 'LoginView' })

                    } else if(response.data.message === 2){

                        this.errorMessage = "The 6 digit code you entered doesn't match what was sent in the email. Please check again."

                        console.log("The 6 digit code you entered doesn't match what was sent in the email. Please check again.")

                    } else{

                        this.errorMessage = "Something went wrong."

                        console.log("Something went wrong.")

                    }

                    
                }).catch((err) => {

                    console.log(err)
                    
                });

            // console.log(formData)
        },


        checkRoute(){
            
            const route = useRoute()
            const currentUrl = computed(() => route.path)

            // console.log('FIRED CHECK URL CHECK', currentUrl.value)

            // const isUrlMatch = this.urlsArray.includes(currentUrl);
            const isUrlMatch = this.urlsArray.some(url => url === currentUrl.value);

            if(isUrlMatch){

                this.showGoBackArrow = false
                // console.log('YES THERES A MATCH')

            }else{

                this.showGoBackArrow = true
                // console.log('NO FUNCKING MATCH')

            }

        },


        async cancelSubscription(){

            BillingService.cancelPaddleSubscription()
                .then((response) => {

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        }


    }

})

