<template>
    <div>

        <nav class="navbar fixed-top navbar-expand-md">

            <div class="container-xxl">

                <a class="navbar-brand" style="text-decoration: none; color: white;" href="/">
            
               <img :src="require(`@/assets/fem-logo.png`)" width="100" />
                </a>



                <!-- toggle button for mobile nav -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav">
                    <img src="https://femniqe.nyc3.cdn.digitaloceanspaces.com/general_imgs/toggle.png" width="35">
                </button>



                <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">

                    <ul class="navbar-nav">

                        <!-- <li class="nav-item">
                            <a class="nav-link">Challenges</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#">Workouts</a>
                        </li> -->


                        <li v-if="authStore.token == ''" class="nav-item">

                            <a class="nav-link" href="/auth/login">Login</a>

                        </li>
               


                    </ul>


                    <!-- <div class="text-center">
                        <img src="https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg.jpg"
                            class="profileImg">
                    </div> -->

                </div>



            </div>

        </nav>

    </div>
</template>

<script>
import { useAuthStore } from '../stores/AuthStore'

export default {


    setup() {

        const authStore = useAuthStore()


        return {
            authStore
        }

    }


};
</script>

<style scoped>
.navbar-toggler {
    /* background-color: rgb(229, 25, 137) !important; */
    color: white !important;
    padding: 0px;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.navbar {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

.nav-link {
    color: rgb(5, 2, 2);
    font-weight: bold;
}

.nav-link:hover {
    color: rgb(214, 21, 166);
}

.profileImg {
    width: auto;
    height: 35px;
    clip-path: circle();
}

.nav-link{
    color: white;
    width: 100px !important;
    /* padding: 7px; */
    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
    border-radius: 5px;
}


</style>