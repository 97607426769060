
import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'
 

export default{ 


    async cancelPaddleSubscription(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/cancel/paddle/subscritpion');
    },






    


    

}