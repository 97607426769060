<template>
    <div>

        <!-- <NavBar /> -->


        <div class="container">


            <div class="row">

                <div class="col">
                    <h2 id="headerText"><b>{{ workoutPlayerStore.category.name }}</b></h2>
                </div>

            </div>


            <div class="row g-2">

                <div class="col-sm" v-for="(workout, index) in workoutPlayerStore.categoryWorkoutsList" :key="index">


                    <router-link class="linkStyle" :to="{ name: 'ViewNormalWorkout', params: { slug: workout.slug } }">

                        <div class="challengeItem">

                            <div class="card">

                                <img :src="workout.thumbnail_image" class="card-img-top">

                                <div class="card-body">
                                    <div class="bodyText">
                                        {{ workout.name }}
                                    </div>
                                </div>

                            </div>

                        </div>


                    </router-link>


                </div>

            </div>



        </div>


        <Footer />

    </div>
</template>

<script>
// import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import { useWorkoutPlayerStore } from '@/stores/WorkoutPlayerStore';
import { useAuthStore } from '@/stores/AuthStore';


export default {
    name: 'ViewACategoryWorkouts',
    components: {
        // NavBar,
        Footer,
    },

    props: ['slug'],

    setup(props) {

        const workoutPlayerStore = useWorkoutPlayerStore()
        const authStore = useAuthStore()

        //fetch workours by category
        workoutPlayerStore.fetchWorkoutsByCategory(props.slug)

        //check url to toggle arrow
        authStore.checkRoute()


        return {
            workoutPlayerStore
        }
    }


};
</script>

<style scoped>
.container {
    margin-top: 110px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    float: left;
    font-size: 17px;
}

.card {
    border: none !important;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.challengeItem {
    min-width: 200px;
    margin: 5px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>