import axios from 'axios';


const apiClient = axios.create({

    baseURL: process.env.VUE_APP_BACKEND_API_URL,
    withCredentials: true,
    
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + localStorage.getItem('token') 
    }


})



export default apiClient;