<template>
    <div>

        <!-- <NavBar /> -->


        <div class="container">


            <div class="row">

                <h2 id="headerText"><b>{{ challengeStore.challengeDetails.name }}</b></h2>



            </div>

            <div v-for="(week, index) in challengeStore.weeks" :key="index" class="row wrapper"
                @click="setCurrentWeek(week)">

                <div class="col">

                    <div class="weekText">
                        Week {{ week.position + 1 }}
                    </div>

                </div>

                <div class="col">

                    <img class="icon" :src="require(`@/assets/next.png`)" width="20" />

                </div>

            </div>





        </div>


        <!-- Modal -->
        <div class="modal fade" id="previewWeekModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div class="modal-dialog modal-dialog-scrollable">

                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Week 1</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">


                        <!-- <button @click="hidePreviewModal" >HIDE</button> -->

                        <div v-for="(userChallengeHistory, index) in challengeStore.userChallengeHistory" :key="index"
                            class="row dayWrapper">

                        

                            <div v-if="userChallengeHistory.day_info.workout.slug == 'rest'">

                                <p>
                                    <b>
                                        Day {{ userChallengeHistory.day_info.position + 1 }}: Rest
                                    </b>
                                </p>

                            </div>

                            <div v-else>
          
                                <div v-if="userChallengeHistory.is_completed">

                                    <p>
                                        <b>
                                            Day {{ userChallengeHistory.day_info.position + 1 }}:
                                            {{ userChallengeHistory.date_completed }} {{ userChallengeHistory.time_completed }}
                                        </b>
                                    </p>

                                    <div class="col smallText">

                                        <img :src="require(`@/assets/time.png`)" width="15" />
                                         <br>
                                        You trained for {{ challengeStore.getTotalMinsWorkout(userChallengeHistory.your_workout_time) }} minutes 

                                    </div>

                                    <div class="col smallText">

                                        <img :src="require(`@/assets/fire.png`)" width="15" />
                                        <br>
                                        {{ userChallengeHistory.your_calorie_burn }} Calories burned

                                    </div>

                                    <div class="col smallText">

                                        <router-link 
                                        class="linkStyle"
                                        @click="hidePreviewModal"
                                        :to="{ 
                                            name: 'ViewADayinChall', 
                                            params: { slug: userChallengeHistory.challenge.slug, dayId: userChallengeHistory.day_info.id } }"
                                        >

                                            <img :src="require(`@/assets/view.png`)" width="15" />
                                            <br>
                                            View workout
                                        
                                        </router-link>

                                        

                                    </div>

                                </div>
                                <div v-else>

                                    <p>
                                        <b>
                                            Day {{ userChallengeHistory.day_info.position + 1 }}: Not done yet
                                        </b>
                                    </p>


                                </div>

                            </div>



                        </div>

                        <!-- <div class="row dayWrapper">

                            <b>Day 1: Rest</b>

                        </div> -->


                    </div>



                </div>
            </div>
        </div>

        <Footer />

    </div>
</template>
  
<script>
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'
import { Modal } from 'bootstrap'
import { useChallengeStore } from '@/stores/ChallengeStore';
import { useAuthStore } from '@/stores/AuthStore';


export default {

    name: 'ViewAChallengeHistory',

    components: {
        // NavBar,
        Footer,

    },

    props: ['uuid'],

    setup(props) {

        const challengeStore = useChallengeStore()
        const authStore = useAuthStore()

        //fetch weeks progress for this challenge
        challengeStore.fetchgetProgressChallengeWeeks(props.uuid)

        //check url to toggle arrow
        authStore.checkRoute()

        var showPreviewWeekModal = () => {

            var weekModal = new Modal(document.getElementById('previewWeekModal'), {
                keyboard: false
            })

            weekModal.show()
        }

        var hidePreviewModal = () => {

            var weekModal = document.getElementById('previewWeekModal')
            var modal = Modal.getOrCreateInstance(weekModal)

            modal.hide()

        }

        

        var setCurrentWeek = (week) => {

            //fetch a week data
            challengeStore.fetchAWeekWithDaysForProgressReport(week.id)

            showPreviewWeekModal()
        }


        return {
            challengeStore, setCurrentWeek, showPreviewWeekModal, hidePreviewModal
        }




    }




}



</script>
  
  
<style scoped>
.container {
    margin-top: 110px;
    max-width: 1200px !important;
    margin-bottom: 300px;

}


.wrapper {
    margin-bottom: 14px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#headerText {
    float: left;
    font-size: 24px;
    margin-bottom: 25px;
}


.linkStyle {
    text-decoration: none;
    color: inherit;
}

.innerWrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}

.thumIMG {
    border-radius: 10px;
    float: left;
}


.subHeader {
    font-size: 13px;
    float: left;
    text-align: left;
}

.mainHeader {
    font-size: 13px;
    float: left;
    text-align: left;
    font-weight: bold;
}

.weekText {
    font-size: 18px;
    font-weight: bold;
}


.icon {
    float: right;
}

.dayWrapper {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.smallText {
    font-size: 12px;
    margin-bottom: 13px;
}
</style>