
<template>
    <div>
        <!-- <NavBar /> -->

        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 id="headerText"><b>Workout Reports</b></h2>
                </div>
            </div>

            <div class="row">

                <div class="col">

                    <div v-for="(workoutHistory, index) in workoutPlayerStore.workoutHistory" :key="index" class="row dayWrapper">

                       <p class="workoutName">{{workoutHistory.workout.name}}</p> 

                        <div class="col smallText">
                            <img :src="require(`@/assets/time.png`)" width="15" />
                            <br />
                            You trained for {{ workoutPlayerStore.calcTotalMinsWorkout(workoutHistory.your_workout_time) }} minutes
                        </div>

                        <div class="col smallText">
                            <img :src="require(`@/assets/fire.png`)" width="15" />
                            <br />
                            {{ workoutHistory.your_calorie_burn }} Calories burned
                        </div>

                        <div class="col smallText">

                            <router-link class="linkStyle" :to="{name: 'ViewNormalWorkout', params: { slug: workoutHistory.workout.slug }}">

                                <img :src="require(`@/assets/view.png`)" width="15" />
                                <br />
                                View workout

                            </router-link>

                            
                        </div>


                    </div>
                    

                </div>



            </div>
        </div>

        <Footer />
    </div>
</template>
  
<script>
// import NavBar from "../../components/NavBar.vue";
import Footer from "../../components/Footer.vue";
import { useWorkoutPlayerStore } from "@/stores/WorkoutPlayerStore";
import { useAuthStore } from '@/stores/AuthStore';


export default {
    name: "WorkoutReportsList",

    components: {
        // NavBar,
        Footer,
    },

    setup() {
        const workoutPlayerStore = useWorkoutPlayerStore();
        const authStore = useAuthStore()

        //fetch all workout history
        workoutPlayerStore.fetchAllWorkoutHistoryList();

        //check url to toggle arrow
        authStore.checkRoute()
        

        return {
            workoutPlayerStore,
        };
    },
};
</script>
  
  
<style scoped>
.container {
    margin-top: 110px;
    max-width: 1200px !important;
    margin-bottom: 300px;
}

.wrapper {
    max-width: 120px;
    margin: 10px;
    padding: 10px;
    background: linear-gradient(180deg, #ecf9ff 0%, #ffe7cc 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#headerText {
    float: left;
    font-size: 24px;
    margin-bottom: 25px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}

.innerWrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}

.thumIMG {
    border-radius: 10px;
    /* max-width: 100px; */
    float: left;
}

.subHeader {
    font-size: 12px;
    float: left;
    text-align: left;
}

.mainHeader {
    font-size: 13px;
    float: left;
    text-align: left;
    font-weight: bold;
    padding-bottom: 0px;
    padding-top: 10px;
}

.deleteIcon {
    width: 15px;
    float: right;
}

.modal-title {
    font-size: 15px !important;
}

.startBtn {
    color: white;
    font-weight: bold;
    background-color: #ea55a0;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    margin-top: 20px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.dayWrapper {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.workoutName{
    text-align: left;
    font-size: 17px;
    font-weight: bold;
}

</style>