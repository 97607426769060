<template>


  <div v-if="authStore.isUserLoggedIn">
    <MobileStickyHeaderVue v-if="!currentUrl.includes('player')" />
  </div>

  <router-view />

  <div v-if="authStore.isUserLoggedIn">
    <MobileBottomNavBarVue v-if="!currentUrl.includes('player')" />
  </div>



</template>

<script>

import MobileBottomNavBarVue from '@/components/MobileBottomNavBar.vue'
import MobileStickyHeaderVue from '@/components/MobileStickyHeader.vue'
import { useRoute } from 'vue-router';
import { computed } from 'vue'
import { useAuthStore } from '@/stores/AuthStore';


export default {

  components: {
    MobileBottomNavBarVue,
    MobileStickyHeaderVue
  },

  setup() {


    const route = useRoute()
    const authStore = useAuthStore()


    
    const test = () => {

      if(window.location.href.includes('/')){

        authStore.shouldHideMobilNav = true

      }
      
    }

    test()

    //using this check to hide navs on player page 
    const currentUrl = computed(() => route.path)

    return {
      currentUrl, authStore
    }

  },



};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
