import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import '@popperjs/core'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'



//make router aviable in all stores
const pinia = createPinia()

pinia
.use(({ store }) => {
    store.router = markRaw(router)
})
.use(piniaPluginPersistedstate)


createApp(App).use(pinia).use(bootstrap).use(router).mount('#app')
