<template>
    <div>


        <div class="container">


            <div class="row">

                <div class="col">
                    <h2 id="headerText"><b>Workouts</b></h2>
                </div>

            </div>

            <div class="row">

                <div class="col">
                    <input v-model="searchTerm" class="form-control searchField" type="text"
                        placeholder="Search workouts...">
                </div>

            </div>



            <div class="row g-2">

                <div class="col-sm" v-for="(workout, index) in filteredExercises" :key="index">


                    <router-link class="linkStyle" :to="{ name: 'ViewNormalWorkout', params: { slug: workout.slug } }">

                        <div class="challengeItem">

                            <div class="card">

                                <img :src="workout.thumbnail_image" class="card-img-top">

                                <div class="card-body">
                                    <div class="bodyText">
                                        {{ workout.name }}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </router-link>



                </div>


            </div>



        </div>


    </div>
</template>

<script>
import { useWorkoutPlayerStore } from '@/stores/WorkoutPlayerStore';
import { computed, ref } from 'vue';


export default {
    name: 'WorkoutsList',

    components: {

    },

    setup() {

        const workoutPlayerStore = useWorkoutPlayerStore()

        //fetch workout list
        workoutPlayerStore.fetchWorkoutsListForSearch()

        const searchTerm = ref('')

        const workouts = computed(() => {
            return workoutPlayerStore.allWorkoutsList;
        });

        const filteredExercises = computed(() => {

            //search field empty just return all list
            if (searchTerm.value === '') {
                return workouts.value;
            }

            const term = searchTerm.value.toLowerCase();

            return workouts.value.filter((workout) => {
                return workout.name.toLowerCase().includes(term);
            });

        });


        return {
            workoutPlayerStore, searchTerm, filteredExercises
        }


    }


};
</script>


<style scoped>
.container {
    margin-top: 80px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

#headerText {
    float: left;
    font-size: 17px;
}

.card {
    min-width: 300px;
    border: none !important;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.challengeItem {
    min-width: 200px;
    margin: 5px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


.searchField {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%) !important;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: aqua;
    border: none;
}
</style>