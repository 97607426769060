<template>
  <div>

    <!-- <NavBar /> -->

    <!-- <MobileStickyHeaderVue/> -->


    <div class="container">



      <div class="row">

        <!-- <p>FROM STORE: {{ authStore.token }}  </p>  -->


        <div class="col">
          <h2 id="headerText"><b>Challenges</b></h2>
        </div>

        <div class="col">
          <router-link :to="{ name: 'ViewAllChallenges' }">
            <span style="float: right; font-size: 14px">View All</span>
          </router-link>
        </div>

      </div>



      <div class="row g-2" style="margin-bottom: 30px;">

        <Carousel :settings="settings" :breakpoints="breakpoints">

          <Slide v-for="(challenge, index) in challengeStore.challenges" :key="index">


            <router-link class="linkStyle" :to="{ name: 'ViewChallDetail', params: { slug: challenge.slug } }">

              <div class="" id="challengeItem">

                <div class="card">

                  <img :src="challenge.featured_image_url" class="card-img-top">

                  <div class="card-body">
                    <div class="bodyText">
                      <b>{{ challenge.name }}</b>
                    </div>
                  </div>

                </div>

              </div>

            </router-link>



          </Slide>

          <template #addons>
            <Navigation />
          </template>

        </Carousel>

      </div>


      <!-- category with workouts -->
      <div class="row g-2" v-for="(category, index) in challengeStore.categories" :key="index"
        style="margin-bottom: 25px;">

        <div class="col-8">
          <h2 class="categoryheaderText"><b>{{ category.name }} Workouts</b></h2>
        </div>


        <div class="col">
          <router-link :to="{ name: 'ViewACategoryWorkouts', params: { slug: category.slug } }">
            <span style="float: right; font-size: 14px">View All</span>
          </router-link>
        </div>

        <Carousel :settings="settings" :breakpoints="breakpoints">

          <Slide v-for="(workout, index) in category.workouts_no_rest" :key="index">

            <router-link class="linkStyle" :to="{ name: 'ViewNormalWorkout', params: { slug: workout.slug } }">

              <div class="" id="challengeItem">

                <div class="card">

                  <img :src="workout.thumbnail_image" class="card-img-top">

                  <div class="card-body">
                    <div class="bodyText">
                      <b>{{ workout.name }}</b>
                    </div>
                  </div>

                </div>


              </div>

            </router-link>



          </Slide>

          <template #addons>
            <Navigation />
          </template>

        </Carousel>

      </div>



    </div>


    <Footer />

    <!-- <MobileBottomNavBarVue/> -->

  </div>
</template>

<script>
// import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { useAuthStore } from '../stores/AuthStore'
import { useChallengeStore } from '../stores/ChallengeStore'

// import MobileBottomNavBarVue from '@/components/MobileBottomNavBar.vue'
// import MobileStickyHeaderVue from '@/components/MobileStickyHeader.vue'


// import { defineComponent } from 'vue'
// import { Carousel, Navigation, Slide } from 'vue3-carousel'
// import 'vue3-carousel/carousel.css'
// import { setgroups } from 'process'

export default {

  name: 'HomeView',

  components: {
    // NavBar,
    Footer,
    Carousel,
    Slide,
    Navigation,
    // MobileBottomNavBarVue,
    // MobileStickyHeaderVue
  },


  setup() {

    const authStore = useAuthStore()
    const challengeStore = useChallengeStore()

    //fetch data
    challengeStore.fetchHomePageData()

    //check url to toggle arrow
    authStore.checkRoute()

    const settings = {
      itemsToShow: 2,
      snapAlign: 'start',
      touchDrag: false
    }

    const breakpoints = {
      150: {
        itemsToShow: 1.3,
        snapAlign: 'center',
        transition: 500
      },

      200: {
        itemsToShow: 1.3,
        snapAlign: 'center',
        transition: 500
      },

      250: {
        itemsToShow: 1.3,
        snapAlign: 'center',
        transition: 500
      },

      // 300px and up
      300: {
        itemsToShow: 2,
        snapAlign: 'center',
        transition: 500
      },

      // 300px and up
      400: {
        itemsToShow: 2.5,
        snapAlign: 'center',
        transition: 500
      },
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
        transition: 500
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
        transition: 500
      },
    }


    authStore.showGoBackArrow = false


    return {
      authStore, challengeStore, settings, breakpoints
    }

  }



}



</script>


<style scoped> .container {
   margin-top: 110px;
   max-width: 1200px !important;
   /* margin-right: 50px;
  margin-left: 50px; */

   /* background-color: aqua; */
 }


 #headerText {
   float: left;
   font-size: 20px;
 }

 .categoryheaderText {
   float: left;
   font-size: 14px;
 }

 #challengeItem {
   /* min-width: 200px; */
   margin: 5px;
   /* padding: 5px;
   margin-bottom: 20px; */
   /* background-color: aqua; */
 }

 #workoutItem {
   /* padding: 5px; */
   /* background-color: aqua; */
 }

 .workoutCard {
   /* width: 150px; */
 }

 .card-body {
   font-size: 13.5px !important;
 }

 .linkStyle {
   text-decoration: none;
   color: inherit;
 }

 .card {
   background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
   border: none !important;
   border-radius: 6px;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 9px;
   height: 210px;

 }
</style>