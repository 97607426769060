<template>
    <div>

        <!-- <MobileStickyHeaderVue/> -->



        <div class="container">

           
            <div class="row">

                <div class="col">
                    <h2 id="headerText"><b>Reports</b></h2>
                </div>

            </div>


            <div class="row">

                <div class="col-sm">

                    <router-link class="linkStyle" :to="{ name: 'ChallengeReportsList' }">

                        <div class="wrapper">

                            <center>
                                <div class="innerWrapper">

                                    <img class="titles" :src="require(`@/assets/chart.png`)" width="30" />
                                    <h4>Challenge Reports</h4>

                                </div>
                            </center>


                        </div>

                    </router-link>




                </div>

                <div class="col-sm">

                    <router-link class="linkStyle" :to="{ name: 'WorkoutReportsList' }">

                        <div class="wrapper">

                            <center>
                                <div class="innerWrapper">

                                    <img class="titles" :src="require(`@/assets/fire.png`)" width="30" />
                                    <h4>Workout Reports</h4>

                                </div>
                            </center>

                        </div>

                    </router-link>


                </div>



            </div>




        </div>



        <Footer />

        <!-- <MobileBottomNavBarVue/> -->

    </div>
</template>
  
<script>
import { useAuthStore } from '@/stores/AuthStore'
// import NavBar from '../../components/NavBar.vue'
import Footer from '../../components/Footer.vue'

// import MobileBottomNavBarVue from '@/components/MobileBottomNavBar.vue'
// import MobileStickyHeaderVue from '@/components/MobileStickyHeader.vue'



export default {

    name: 'ProgressReports',

    components: {
        // NavBar,
        Footer,
        // MobileBottomNavBarVue,
        // MobileStickyHeaderVue

    },

    setup() {

        const authStore = useAuthStore()

        authStore.checkRoute()


        return {
            authStore
        }
    }




}



</script>
  
  
<style scoped>
.container {
    margin-top: 110px;
    max-width: 1200px !important;
    margin-bottom: 300px;

}


.wrapper {
    margin-bottom: 10px;
    padding: 20px;
    background: linear-gradient(180deg, #ECF9FF 0%, #FFE7CC 100%);
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#headerText {
    float: left;
    font-size: 24px;
    margin-bottom: 25px;
}

.titles {
    margin-bottom: 10px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}

.innerWrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}
</style>