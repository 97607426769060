<template>
    <div>
        <NavBar />

        <div class="container">

            <div class="mainWrapper">

                

                <center>


                    

                    <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert" style="max-width: 400px;">
                        {{ authStore.errorMessage }}
                    </div>
                    
                    <div>

                        <h3>Login</h3>


                        <p>Don’t have an account? Sign up here</p>

                    </div>

                    <div class="row wrapper">

                        <form @submit.prevent="authStore.login">

                            <div class="mb-3">

                                <label class="form-label">Email</label>
                                <input v-model="authStore.loginFormData.email" type="email" class="form-control">
                        
                            </div>

                            <div class="mb-3">

                                <label class="form-label">Password</label>
                                <input v-model="authStore.loginFormData.password" type="password" class="form-control">

                            </div>


                            <button type="submit" class="btn btn-primary loginBtn"><b>LOGIN</b></button>

                        </form>


                    </div>
                </center>

            </div>

        </div>

    </div>
</template> 

<script>
import { ref } from 'vue';
import NavBar from '../../components/NavBar.vue'
import { useAuthStore } from '../../stores/AuthStore'

export default {
    name: 'LoginView',

    components: {
        NavBar
    },

    setup(){

        const authStore = useAuthStore()

        const vendorId = ref(process.env.VUE_APP_PADDLE_VENDOR_ID)
        // const vendorId = ref(10000)

        
 
        return {
            authStore, vendorId
        }


    }


};
</script>

<style scoped>
.container {
    /* background-color: aqua; */
    padding: 25px;
}

.wrapper {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 600px;
    border-radius: 20px;
    padding: 20px;
}

.logoImg {
    width: 300px;
}


.mainWrapper {
    margin-top: 90px;
}


.form-label{
    float: left;
}

.loginBtn{
    color: rgb(255, 255, 255);
    background-color: #5aca5a !important;
    border: none;
    width: 200px;
}

</style>