import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'
import HomeView from '../views/HomeView.vue'
import ViewAllChallenges from '../views/Challenges/ViewAllChallenges'
import ViewACategoryWorkouts from '../views/ViewACategoryWorkouts'
import ViewChallDetail from '../views/Challenges/ViewChallDetail'
import ViewADayinChall from '../views/Challenges/ViewADayinChall'
import WorkoutPlayer from '../views/Workouts/WorkoutPlayer'
import ViewNormalWorkout from '../views/Workouts/NormalWorkout'
import NormalWorkoutPlayer from '../views/Workouts/NormalWorkoutPlayer'

import LoginView from '../views/Auth/Login'
import RegisterView from '../views/Auth/Register'
import VerifyEmail from '../views/Auth/VerifyEmail'

import ProgressReports from '../views/Progress/Reports'
import ChallengeReportsList from '../views/Progress/ChallengeReportsList'
import ViewAChallengeHistory from '../views/Progress/ViewAChallengeHistory'
import WorkoutReportsList from '../views/Progress/WorkoutReportsList'
import WorkoutsList from '../views/Workouts/WorkoutsList'

import MyAccount from '../views/Account/MyAccount'

import LandingPage from '../views/PublicPages/LandingPage'


const routes = [


  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },

  
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
      
    },
  },

  {
    path: '/challenges',
    name: 'ViewAllChallenges',
    component: ViewAllChallenges,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/category/workouts/:slug',
    name: 'ViewACategoryWorkouts',
    component: ViewACategoryWorkouts,
    props: true,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/challenge/:slug',
    name: 'ViewChallDetail',
    component: ViewChallDetail,
    props: true,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/challenge/:slug/day/:dayId',
    name: 'ViewADayinChall',
    component: ViewADayinChall,
    props: true,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/workout/:slug/',
    name: 'ViewNormalWorkout',
    component: ViewNormalWorkout,
    props: true,
    
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/workout/:slug/player',
    name: 'NormalWorkoutPlayer',
    component: NormalWorkoutPlayer,
    props: true,
    
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/challenge/:slug/day/:dayId/player',
    name: 'WorkoutPlayer',
    component: WorkoutPlayer,
    props: true,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },
  },

  {
    path: '/auth/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
    
  },

  {
    path: '/auth/register',
    name: 'RegisterView',
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },

  {
    path: '/auth/verify',
    name: 'VerifyEmail',
    component: VerifyEmail,
   
  },


  {
    path: '/progress',
    name: 'ProgressReports',
    component: ProgressReports,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },

  },

  {
    path: '/progress/challenges',
    name: 'ChallengeReportsList', 
    component: ChallengeReportsList,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },

  },

  {
    path: '/progress/workouts',
    name: 'WorkoutReportsList',
    component: WorkoutReportsList,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },

  },

  {
    path: '/progress/challenge/:uuid',
    name: 'ViewAChallengeHistory',
    component: ViewAChallengeHistory,
    props: true,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },


  },


  {

    path: '/workouts',
    name: 'WorkoutsList',
    component: WorkoutsList,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },

  },

  {

    path: '/account',
    name: 'MyAccount',
    component: MyAccount,

    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')
    },

  },

  



  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  //so that each new page scroll to the top
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }

})



export default router
