import WorkoutService from "@/services/WorkoutService";
import { defineStore } from "pinia";
import final321Audio from '@/assets/audio/321final.mp3';
import restAudio from '@/assets/audio/rest_audio.mp3';
import last3secWorkoutAudio from '@/assets/audio/last3secWorkout.mp3';
import endOfWorkoutAudio from '@/assets/audio/finalendworkout.mp3';


export const useWorkoutPlayerStore = defineStore('workoutPlayerStore', {

    state: () => ({

        isPortrait: true,
        workoutDetails: {},
        workoutSequence: [], 
        exercisesCount: '',

        videoIndex: 0,
        timer: 0,
        timerRunning: false,
        interval: '',

        previewTimerCount: 10,
        previewTimerEnabled: true,

        workoutTimerCount: 0,
        workoutTimerEnabled: false,

        restTimerCount: 0,
        restTimerEnabled: false,

        stillShowCurrentControls: true,

        isModalOpen: true,
        endOfWorkoutmodal: false,

        audioObj: null,

        // 1: preview, 2: workout  3: rest
        currentplayerControl: 1,

        progressPercentage: 0,

  
        challengeWorkoutCompletedFormData: {
            day_id: '',
            your_workout_time: '',
            your_calorie_burn: '',
            time_completed: ''
        },
        currentChallenge: {},

        normalWorkoutCompletedFormData: {
            uuid: '',
            your_workout_time: '',
            your_calorie_burn: '',
            time_completed: ''
        },

        normaWorkoutDetails: {},
        rounds: [],
        round_amt: '',

        isWorkoutSuccessfullyLogged: false,

        currentAudio: 'https://femniqe.nyc3.cdn.digitaloceanspaces.com/exercise_name_audios%2F180%20Banded%20Jump%20Squat.mp3',

        workoutHistory: [],
        category: {},
        categoryWorkoutsList: [],

        allWorkoutsList: [],
        searchQuery: ''




    }),

    actions: {


        async fetchWorkoutSequenceForPlayer(dayId) {

            //set day id in state for marking workout complete
            this.challengeWorkoutCompletedFormData.day_id = dayId

            WorkoutService.getWorkoutSequenceForPlayer(dayId)
                .then((response) => {

                    this.workoutDetails = response.data.workoutDetails
                    this.workoutSequence = response.data.workoutSequence
                    this.exercisesCount = response.data.exercisesCount
                    this.currentChallenge = response.data.currentChallenge

                    //load first exercise workout time
                    this.workoutTimerCount = this.workoutSequence[this.videoIndex].workout_time
                    // this.currentAudio = this.workoutSequence[this.videoIndex].exercise_details.exercise_name_audio_url

                    console.log(response.data)

                }).catch((err) => {

                    console.log(err)

                });

        },


        startMainTimer() {



            this.interval = setInterval(() => {

                this.timer += 1

                //only run preview timer if enabled
                if (this.previewTimerEnabled) {

                    this.currentplayerControl = 1
                    this.previewTimeCountdown()

                }

                //only run workout timer if enabled
                if (this.workoutTimerEnabled) {

                    this.currentplayerControl = 2
                    this.startWorkoutTimeCountdown()
                }

                //only run rest timer if enabled
                if (this.restTimerEnabled) {

                    this.currentplayerControl = 3
                    this.startRestTimerCountdown()
                }



            }, 1000)

            this.timerRunning = true
        },

        pauseMainTimer() {

            this.pauseAudio()

            clearInterval(this.interval)
            this.timerRunning = false

        },

        previewTimeCountdown() {


            this.previewTimerEnabled = true
            this.previewTimerCount--


            //plays exercise name
            if (this.previewTimerCount == 9) {


                // this.currentAudio = this.workoutSequence[this.videoIndex].exercise_details.exercise_name_audio_url
                this.playAudio(this.workoutSequence[this.videoIndex].exercise_details.exercise_name_audio_url)

                // console.log(this.workoutSequence[this.videoIndex].exercise_details.exercise_name_audio_url)
                console.log('PLAY EXERCISE NAME')

            }

            //plays beep at last 3 seconds
            if (this.previewTimerCount == 3) {

                // this.currentAudio = final321Audio

                this.playAudio(final321Audio)
                console.log('PLAY LAST 3 SECONDS AUDIO')

            }

            if (this.previewTimerCount == 0) {

                //reset to 10
                this.previewTimerCount = 10

                //disable preview countdown
                this.previewTimerEnabled = false

                //start workout
                this.startWorkoutTimeCountdown()

                console.log('START WORKOUT')


            }

        },

        enablePreviewTimerCountdown() {

            this.previewTimerEnabled = true

            if (!this.timerRunning) {
                //enable main timer too
                this.startMainTimer()
            }

        },

        pausePreviewTimerCountdown() {

            this.previewTimerEnabled = false

            //pause main timer too
            this.pauseMainTimer()

        },

        startWorkoutTimeCountdown() {

            this.workoutTimerEnabled = true

            this.workoutTimerCount--

            //plays beep at last 4 seconds
            if (this.workoutTimerCount == 4) {

                // this.currentAudio = last3secWorkoutAudio
                this.playAudio(last3secWorkoutAudio)
                console.log('play audio for last 4 seconds')

            }


            if (this.workoutTimerCount == 0) {

                //reset to 0
                this.workoutTimerCount = 0

                //disable workout countdown
                this.workoutTimerEnabled = false

                //trigger next()
                this.next()

                console.log('fire next function')

            }

        },

        pauseWorkoutTimerCountdown() {

            this.workoutTimerEnabled = false

            //pause main timer too
            this.pauseMainTimer()

        },


        enableWorkoutTimerCountdown() {

            this.workoutTimerEnabled = true

            if (!this.timerRunning) {
                //enable main timer too
                this.startMainTimer()
            }

        },


        startRestTimerCountdown() {

            this.restTimerEnabled = true

            const originalRestTime = this.workoutSequence[this.videoIndex].rest_time
            const minus1FromOriginalRestTime = originalRestTime - 1

            this.restTimerCount--

            //play rest audio in first second
            if (this.restTimerCount == minus1FromOriginalRestTime) {

                // this.currentAudio = restAudio
                this.playAudio(restAudio)
                console.log('play audio in first second')

            }
            

            //plays beep at last 4 seconds
            if (this.restTimerCount == 4) {

                // this.currentAudio = last3secWorkoutAudio
                this.playAudio(last3secWorkoutAudio)
                console.log('play audio for last 4 seconds')

            }


            if (this.restTimerCount == 0) {
                

                //reset to 10
                this.restTimerCount = 0

                //disable rest time countdown
                this.restTimerEnabled = false

                this.next()

                console.log('fire next function')

            }

        },

        pauseRestTimerCountdown() {

            this.restTimerEnabled = false

            //pause main timer too
            this.pauseMainTimer()

        },

        enableRestTimerCountdown() {

            this.restTimerEnabled = true

            if (!this.timerRunning) {
                //enable main timer too
                this.startMainTimer()
            }

        },

        next() {


            if (this.videoIndex == (this.workoutSequence.length - 1)) {

                //stop timer
                clearInterval(this.interval)

                //show modal
                this.endOfWorkoutmodal = true

                // this.currentAudio = endOfWorkoutAudio
                this.playAudio(endOfWorkoutAudio)
                console.log("end of workout")

            } 
            
            if(this.videoIndex <= this.workoutSequence.length){

                this.videoIndex++

                this.calcProgressPercentage()

                //check if type is exercise
                if (this.workoutSequence[this.videoIndex].workout_time) {

                    //load the exercise workout time
                    this.workoutTimerCount = this.workoutSequence[this.videoIndex].workout_time

                    //start preview countdown
                    this.previewTimeCountdown()

                 

                } else {


                    //load the rest time
                    this.restTimerCount = this.workoutSequence[this.videoIndex].rest_time

                    //start rest time countdown
                    this.startRestTimerCountdown()

                }

            }

        },


        skipToNext() {

            if (this.videoIndex < (this.workoutSequence.length - 1)) {

                //pause anything running except main timer
                this.previewTimerEnabled = false
                this.workoutTimerEnabled = false
                this.restTimerEnabled = false

                //increment index
                this.videoIndex++

                //update progress bar
                this.calcProgressPercentage()

                if (this.videoIndex == this.workoutSequence.length) {

                    //stop timer
                    clearInterval(this.interval)



                } else {

                    //check if type is exercise
                    if (this.workoutSequence[this.videoIndex].workout_time) {

                        //load the exercise workout time
                        this.workoutTimerCount = this.workoutSequence[this.videoIndex].workout_time

                        //start preview countdown
                        this.previewTimerCount = 10 //reset 
                        this.previewTimeCountdown()

                    } else {


                        //load the rest time
                        this.restTimerCount = this.workoutSequence[this.videoIndex].rest_time

                        //start rest time countdown
                        this.startRestTimerCountdown()

                        this.previewTimerCount = 10 //reset 

                    }

                }

            }

        },

        skipToPrev() {

            if (this.videoIndex > 0) {

                //pause anything running except main timer
                this.previewTimerEnabled = false
                this.workoutTimerEnabled = false
                this.restTimerEnabled = false

                //increment index
                this.videoIndex--

                this.calcProgressPercentage()

                //check if type is exercise
                if (this.workoutSequence[this.videoIndex].workout_time) {

                    //load the exercise workout time
                    this.workoutTimerCount = this.workoutSequence[this.videoIndex].workout_time

                    //start preview countdown
                    this.previewTimerCount = 10 //reset 
                    this.previewTimeCountdown()

                } else {


                    //load the rest time
                    this.restTimerCount = this.workoutSequence[this.videoIndex].rest_time

                    //start rest time countdown
                    this.startRestTimerCountdown()

                    this.previewTimerCount = 10 //reset 

                }

            }


        },

        openModal() {
            this.isModalOpen = true;
        },

        showEndOfWorkoutModal() {
            this.endOfWorkoutmodal = true
        },

        closeModal() {
            this.isModalOpen = false;
        },

        closeModalAndStartMainTimer() {

            this.closeModal()

            this.startMainTimer()

        },


        playAudio(url) {
            
            this.audioObj = new Audio(url)
            this.audioObj.load()
            this.audioObj.play()

            console.log(url)
        },


        pauseAudio() {
            this.audioObj.pause()
        },

        showPreviewControls() {

            if (this.previewTimerEnabled == (this.workoutTimerEnabled == this.restTimerEnabled)) {

                return true

            } else {
                return false
            }

        },

        showWorkoutControls() {

            if (this.workoutTimerEnabled == (this.previewTimerEnabled == this.restTimerEnabled)) {

                return true

            } else {
                return false
            }

        },

        calcProgressPercentage() {

            this.progressPercentage = (this.videoIndex / this.workoutSequence.length) * 100

        },

        //for end of player
        getTotalMinsWorkout() {
            let minutes = Math.round(this.timer / 60)
            // console.log(minutes)
            return minutes
        },

        //to render in reports
        calcTotalMinsWorkout(your_workout_time) {

            let minutes = Math.round(your_workout_time / 60)
            return minutes
            
        },

        estimatedCalorieBurned() {
        
            let avgCalorieBurnPerMinute = 5
            let minutes = Math.round(this.timer / 60)
            
            let totalBurned = avgCalorieBurnPerMinute * minutes
            
            return totalBurned
            
        },

        async markChallengeWorkotAsComplete(){ 

            //get current time
            var today = new Date();
            var currentTime = today.getHours() + ":" + today.getMinutes();

            this.challengeWorkoutCompletedFormData.your_workout_time = this.timer
            this.challengeWorkoutCompletedFormData.your_calorie_burn = this.estimatedCalorieBurned()
            this.challengeWorkoutCompletedFormData.time_completed = currentTime


            WorkoutService.markChallWorkoutDone(this.challengeWorkoutCompletedFormData) 
                .then((response) => {

                    this.isWorkoutSuccessfullyLogged = true

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

             

        },

        redirectFromChallengeWorkout(){

            this.router.push({ 
                name: 'ViewADayinChall', 
                params: {slug: this.currentChallenge.slug, dayId: this.challengeWorkoutCompletedFormData.day_id } 
            })

        },


        //NORMAL WORKOUT CODE BELOW
        async fetchNormalWorkoutDetails(slug){

            WorkoutService.getNormalWorkoutDetails(slug)
                .then((response) => {

                    this.normaWorkoutDetails = response.data.workoutDetails
                    this.rounds = response.data.rounds
                    this.round_amt = response.data.round_amt

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        async fetchNormalWorkoutSequenceForPlayer(slug){

            WorkoutService.getNormalWorkoutSequenceForPlayer(slug) 
                .then((response) => {

                    this.normaWorkoutDetails = response.data.workoutDetails
                    this.workoutSequence = response.data.workoutSequence
                    this.exercisesCount = response.data.exercisesCount

                    //load first exercise workout time
                    this.workoutTimerCount = this.workoutSequence[this.videoIndex].workout_time

                    console.log(response.data)
                    
                }).catch((err) => {
                    
                    console.log(err)
                });

        },

        async markNormalWorkoutDone(){

            //get current time
            var today = new Date();
            var currentTime = today.getHours() + ":" + today.getMinutes();

            this.normalWorkoutCompletedFormData.uuid = this.normaWorkoutDetails.uuid
            this.normalWorkoutCompletedFormData.your_workout_time = this.timer
            this.normalWorkoutCompletedFormData.your_calorie_burn = this.estimatedCalorieBurned()
            this.normalWorkoutCompletedFormData.time_completed = currentTime
         
            WorkoutService.markNormalWorkoutAsComplete(this.normalWorkoutCompletedFormData)
                .then((response) => {

                    this.isWorkoutSuccessfullyLogged = true

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        redirectFromNormalWorkout(){ 

            //go back to details
            this.router.push({ name: 'ViewNormalWorkout', params: {slug: this.normaWorkoutDetails.slug } })

        },

        async fetchAllWorkoutHistoryList(){

            WorkoutService.getAllWorkoutsHistoryList()
                .then((response) => {

                    this.workoutHistory = response.data.workoutHistory
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },


        async fetchWorkoutsByCategory(slug){ 

            WorkoutService.getWorkoutsByCategory(slug)
                .then((response) => {

                    this.category = response.data.category 
                    this.categoryWorkoutsList = response.data.category.workouts_no_rest
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        playVideo(){

            const videoEl = this.$refs.myVideo

            console.log(videoEl)
            // videoEl.play()

            // const video = document.getElementById('thisvideo');
            // video.play();

            // console.log('worked YESSSS')
            
        },

        async fetchWorkoutsListForSearch(){

            WorkoutService.getWorkoutsListForSearch()
                .then((response) => {

                    this.allWorkoutsList = response.data.allWorkoutsList
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },




        











    }


})