<template>
    <div>
        <!-- <NavBar /> -->

        <div class="container">

            <div class="mainWrapper">

                <center>
                    <div style="max-width: 500px;">

                        <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert">
                            {{ authStore.errorMessage }}
                        </div>

                        <h5>Please enter the 6 digit number that was sent to your email</h5>

                    </div>

                    <div class="row wrapper">

                        <form @submit.prevent="authStore.verifyUserAuthCode">

                            <div class="mb-3">

                                <label class="form-label">CODE</label>
                                <input v-model="authStore.authCode" type="number" class="form-control" placeholder="Enter 6 digit number here" required>
                        
                            </div>



                            <button type="submit" class="btn btn-primary loginBtn"><b>VERIFY</b></button>

                        </form>


                    </div>
                </center>

            </div>

        </div>

    </div>
</template> 

<script>
// import NavBar from '../../components/NavBar.vue'
import { useAuthStore } from '../../stores/AuthStore'

export default {
    name: 'VerifyEmail',

    components: {
        // NavBar
    },

    setup(){

        const authStore = useAuthStore()

 
        return {
            authStore
        }


    }


};
</script>

<style scoped>
.container {
    /* background-color: aqua; */
    padding: 25px;
}

.wrapper {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 600px;
    border-radius: 20px;
    padding: 20px;
}

.logoImg {
    width: 300px;
}


.mainWrapper {
    margin-top: 90px;
}


.form-label{
    float: left;
}

.loginBtn{
    color: rgb(255, 255, 255);
    background-color: #5aca5a !important;
    border: none;
    width: 200px;
}

</style>