<template>
    <div>

        <NavBar />


        <section class="heroSection">


            <div class="heroContent">

                <div>

                    <h1><b>Home Workouts Made Simple</b></h1>
                    <p>Reshape Your Body with Powerful Training Sequences That Give Results.</p>

                    <p><b> Available on IOS and Web App. Andriod (Coming soon)</b></p>
                    <br>


                    <!-- <center>
                        <div class="videoWrapper">
                            <video id="salesVideo" class="salesVideo"
                                poster="https://www.femniqe.com/wp-content/uploads/2023/02/do-glutes-recover-fast-after-workout.jpg"
                                controlsList="nodownload"
                                src="https://femniqe.nyc3.cdn.digitaloceanspaces.com/exercise_videos%2F180%20Hops.mp4">

                                <source type="video/mp4">

                            </video>

                            <img @click="playVideo" class="playButton" :src="require(`@/assets/play_video.png`)"
                                width="100" />

                        </div>
                    </center> -->

                    <!-- <button class="ctaBtn">Get Started</button> <br> -->

                    <a class="ctaBtn" href="/auth/login">Get Started</a>

                </div>



            </div>



        </section>


        <div class="container">



            <div class="row contentWrapper">

                <div class="col">

                    <center>
                        <h4>Workout Challenges Built To Reshape Your Body</h4>
                        <p style="max-width: 750px;">Start your body transformation journey right away with challenges built
                            for
                            results. Available on iPhone or web users. Our workout plans are designed to help you reach your
                            fitness goals faster and simpler.
                        </p>
                    </center>


                    <img style="max-width: 300px;"
                        src="https://femniqe.nyc3.cdn.digitaloceanspaces.com/general_imgs%2Fbth.png">

                </div>



            </div>

            <div class="row contentWrapper">

                <div class="col">

                    <center>
                        <h4>Fresh New Workouts Every Week</h4>
                        <p style="max-width: 750px;">Don’t waste time doing exercises that does nothing for your body. Out
                            team create new workouts that are added every week to target all major parts of the body.</p>

                    </center>

                    <img style="width: 200px;" src="https://www.femniqe.com/wp-content/uploads/2022/12/s5.jpg">

                </div>



            </div>


            <div class="row contentWrapper">

                <div class="col">

                    <center>
                        <h4>Designed with simplicity to keep you focused on what matters</h4>
                        <p style="max-width: 750px;">Which is training your body and getting it primed for transformation.
                            Our carefully designed workout sequences will get to your dream body with using bodyweight, no
                            gym required.</p>

                    </center>

                    <img style="width: 200px;" src="https://www.femniqe.com/wp-content/uploads/2022/12/s1.jpg">

                </div>



            </div>




        </div>


        <section class="pricingSection">


            <center>
                <div class="priceWrapper">

                    <div class="row priceDetails">

                        <h5>Here's what you get:</h5>

                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    You'll have access to the IOS, Andriod (coming soon) and the Web App version with one login.
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    Access to all body transforming challenges from glutes, thighs, waist to upper body
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    Access to all workout sequences designed to hit every part of your body
                                </div>

                            </div>

                        </div>


                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    New workout sequences and challenges added every month
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    Progress reports to keep track of your workout activity
                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col-1">

                                <img class="checkIcon" :src="require(`@/assets/checkmark.png`)" width="19" />

                            </div>

                            <div class="col-11">

                                <div class="checkDesc">
                                    Each exercise video comes with clear directions on how to perform and get the most out
                                    of your exercise routine.

                                </div>

                            </div>

                        </div>


                    </div>


                    <div class="row" style="margin-bottom: 20px;">

                        <div class="col">

                            <div class="priceItem">

                                <h5>1 Month</h5>
                                <p>$9.99 per month</p>

                            </div>



                        </div>

                        <div class="col">

                            <div class="priceItem">

                                <h5>3 Months</h5>
                                <p>$19.99 every 3 months</p>

                            </div>

                        </div>

                        <div class="col">

                            <div class="priceItem">

                                <h5>1 Year</h5>
                                <p>$35.99 per year</p>

                            </div>

                        </div>

                    </div>

                    <a class="ctaBtn" href="/auth/login">Try Us Out For Free</a>


                </div>
            </center>


        </section>

        <Footer />


    </div>
</template>

<script>
/* eslint-disable */
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';



export default {
    name: "LandingPage",

    components: {
        NavBar,
        Footer
    },


    setup() {


        const playVideo = () => {

            const video = document.getElementById('salesVideo');
            video.play();

        }


        return {
            playVideo
        }

    }


};

</script>

<style scoped>
.container {
    margin-top: 50px;
    padding-bottom: 60px;
    max-width: 1200px !important;
}

.heroSection {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 130px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    padding-bottom: 30px;
}

.heroContent {
    padding-bottom: 30px;
}

.salesVideo {
    width: 100%;
    border-radius: 10px;

}

.videoWrapper {
    padding: 10px;
    max-width: 500px;

}

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 1.5rem;
  padding: 0.5rem 1rem; */


}

.ctaBtn {
    width: 50px;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    text-decoration: none;

}


.contentWrapper {
    margin-bottom: 20px;
}

.pricingSection {
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);


}

.priceWrapper {
    max-width: 900px;
}



.priceItem {
    margin: 10px;
    min-width: 250px;
    border-radius: 20px;
    padding: 15px;
    color: rgb(21, 20, 20);
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.priceDetails {
    max-width: 900px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
}

.checkIcon {
    float: right;
}

.checkDesc {
    float: left;
    text-align: left;
}


.bottomCTAbtn {
    width: 100%;
    border: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
    margin-top: 15px;

}
</style>