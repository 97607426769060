
import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'

// //get token and attach it to request
// const authStore = localStorage.getItem('authStore')
// apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token



export default{ 


    async getWorkoutsByCategory(slug){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/workoutsbycatory/' + slug);


    },


    async getWorkoutSequenceForPlayer(dayId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/challenge/workoutsequence/player/' + dayId);

    },

    async markChallWorkoutDone(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/web/challenge/workout/markcompleted', formData);

    },

    async getNormalWorkoutDetails(slug){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/normalworkoutdetails/' + slug);

    },


    async getNormalWorkoutSequenceForPlayer(slug){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/normalworkout/sequence/player/' + slug);

    },


    async markNormalWorkoutAsComplete(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/web/normalworkout/markascomplete/', formData);


    },

    async deleteAWorkoutHistoryRecord(workoutHistoryId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/web/workout/history/delete/' + workoutHistoryId);

    },


    async getAllWorkoutsHistoryList(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/workouts/history/list'); 

    },

    async getWorkoutsListForSearch(){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/web/all/workouts'); 

    },


   


    

}