<template>
    <div>

        <div class="wrapper">


            <div class="progressBar">

                <div class="progressFill" :style="{width: workoutPlayerStore.progressPercentage + '%'}">

                </div>
            
            </div>

           

        </div>
        
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useWorkoutPlayerStore } from '../stores/WorkoutPlayerStore'

export default {
    name: 'WorkoutProgressBar',


    setup(){

        const workoutPlayerStore = useWorkoutPlayerStore() 

        var progressBarValue = ref(20);
        


        return{
            workoutPlayerStore, progressBarValue
        }
    }
   
    
};
</script>

<style scoped>

.progressBar{
    /* min-width: 5px; */
    width: 100%;
    height: 10px;
    background-color: rgb(195, 198, 195);
    border-radius: 10px; 
    overflow: hidden;
    /* margin: 10px; */
}

.wrapper{
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

}

.progressFill{

    height: 100%;
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
    /* background: linear-gradient(90deg, #efd5ff 0%, #515ada 100%); */
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

}

</style>