<template>
    <div>
        <NavBar />

        <div class="container">

            <div class="mainWrapper">

                <center>

                    <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert" style="max-width: 400px;">
                            {{ authStore.errorMessage }}
                    </div>

                    <div>

                        <h3>Join</h3>

                        <p>Already a member? login up here</p>

                    </div>

                    <div class="row wrapper">

                        <form @submit.prevent="authStore.registerUser">

                            <div class="mb-3">

                                <label class="form-label">Name</label>
                                <input v-model="authStore.registerFormData.name" type="text" class="form-control" required>

                            </div>

                            <div class="mb-3">

                                <label class="form-label">Email</label>
                                <input v-model="authStore.registerFormData.email" type="email" class="form-control" required>
                        
                            </div>

                            <div class="mb-3">

                                <label class="form-label">Password</label>
                                <input v-model="authStore.registerFormData.password" type="password" class="form-control" required>

                            </div>

                            <div class="mb-3">

                                <label class="form-label">Confirm Password</label>
                                <input v-model="authStore.registerFormData.password_confirmation" type="password" class="form-control" required>

                            </div>

                            <div v-if="authStore.isLoading">

                                <div style="margin-bottom: -4px;"><b>Loading...</b></div>

                                <img :src="require(`@/assets/loading/abs.gif`)" width="60"/>
                                
                            </div>

                            <div v-else>
                                <button type="submit" class="btn btn-primary loginBtn"><b>JOIN</b></button>
                            </div>

                            

                        </form>


                    </div>
                </center>

            </div>

        </div>

    </div>
</template> 

<script>
import NavBar from '../../components/NavBar.vue'
import { useAuthStore } from '../../stores/AuthStore'

export default {
    name: 'RegisterView',

    components: {
        NavBar
    },

    setup(){

        const authStore = useAuthStore()

 
        return {
            authStore
        }

    }


};
</script>

<style scoped>
.container {
    /* background-color: aqua; */
    padding: 25px;
}

.wrapper {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 600px;
    border-radius: 20px;
    padding: 20px;
}

.logoImg {
    width: 300px;
}


.mainWrapper {
    margin-top: 90px;
}


.form-label{
    float: left;
}

.loginBtn{
    color: rgb(255, 255, 255);
    background-color: #5aca5a !important;
    border: none;
    width: 200px;
}

</style>