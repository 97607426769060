<template>
    <div>

        <div :class="workoutPlayerStore.isPortrait ? 'row bottomNavBar' : ' row bottomLandscapeNavBar'">

            <div class="col-2">


            </div>

            <div class="col-8">

                <div class="row">

                    <div class="col">
                        <img :src="require(`@/assets/rewind.png`)" class="playIcon"
                            @click="workoutPlayerStore.skipToPrev" />
                    </div>

                    <div class="col">

                        <div v-if="workoutPlayerStore.currentplayerControl == 1">

                            <!-- current player: {{ workoutPlayerStore.currentplayerControl }} -->

                            <div v-if="workoutPlayerStore.previewTimerEnabled">

                                <!-- PREVIEW -->

                                <img :src="require(`@/assets/pause.png`)" class="pauseIcon"
                                    @click="workoutPlayerStore.pausePreviewTimerCountdown" />

                            </div>

                            <div v-else>

                                <!-- PREVIEW -->

                                <img :src="require(`@/assets/playBtn.png`)" class="playIcon"
                                    @click="workoutPlayerStore.enablePreviewTimerCountdown" />

                            </div>
                        </div>

                        <div v-if="workoutPlayerStore.currentplayerControl == 2">

                            <!-- current player: {{ workoutPlayerStore.currentplayerControl }} -->

                            <div v-if="workoutPlayerStore.workoutTimerEnabled">

                                <!-- WORKOUT -->

                                <img :src="require(`@/assets/pause.png`)" class="pauseIcon"
                                    @click="workoutPlayerStore.pauseWorkoutTimerCountdown" />

                            </div>

                            <div v-else>

                                <!-- WORKOUT -->

                                <img :src="require(`@/assets/playBtn.png`)" class="playIcon"
                                    @click="workoutPlayerStore.enableWorkoutTimerCountdown" />

                            </div>

                        </div>


                        <div v-if="workoutPlayerStore.currentplayerControl == 3">

                            <!-- current player: {{ workoutPlayerStore.currentplayerControl }} -->

                            <div v-if="workoutPlayerStore.restTimerEnabled">

                                <!-- REST -->

                                <img :src="require(`@/assets/pause.png`)" class="pauseIcon"
                                    @click="workoutPlayerStore.pauseRestTimerCountdown" />

                            </div>

                            <div v-else>

                                <!-- REST -->

                                <img :src="require(`@/assets/playBtn.png`)" class="playIcon"
                                    @click="workoutPlayerStore.enableRestTimerCountdown" />

                            </div>

                        </div>



                    </div>

                    <div class="col">
                        <img :src="require(`@/assets/forward.png`)" class="playIcon"
                            @click="workoutPlayerStore.skipToNext" />
                    </div>
                </div>

            </div>

            <div class="col-2">


            </div>

        </div>

    </div>
</template>

<script>

import { useWorkoutPlayerStore } from '../stores/WorkoutPlayerStore'

export default {

    name: 'NavForWorkoutPlayer',


    setup() {

        const workoutPlayerStore = useWorkoutPlayerStore()

        return { workoutPlayerStore }
    }


};
</script>

<style scoped>
.bottomNavBar {
    padding: 15px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    bottom: 0%;
    width: 100%;
}

/* @media only screen and (max-width: 1000px) {
    .bottomNavBar{
        padding: 15px;
        background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: fixed; 
        bottom:0%;
        width: 100%;
    }
} */

.bottomLandscapeNavBar {
    /* height: 35px; */
    padding: 15px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    bottom: 0%;
    width: 100%;
    opacity: 0.7;
}

.playIcon {
    width: 30px;
}

.pauseIcon {
    width: 30px;
}

.moreIcon {
    float: right;
    width: 20px;
}
</style>